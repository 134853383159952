import axios from '@/setups/axios-setup'

import config from '@/services/config'
import {KnowledgeFile} from "@/types/KnowledgeFile";


export const getKnowledgeFiles = async (search_data: any | null, node_id: number, page: number, pagesize: number) => {
    // 构建请求数据对象
    const data = {
        search_keyword: search_data.keyword, // 搜索关键字
        all: search_data.all,
        text: search_data.text,
        graph: search_data.graph,
        vectorization: search_data.vectorization,
        page: page,                // 当前页码
        page_size: pagesize,       // 每页项目数量
        status: node_id            // 树节点ID
    };
    const response = await axios.post(`${config.apiFileUrl}/knowledge_files`, data);
    return response.data;
};

export const getKnowledgeFileById = async (fileId: number) => {
    const response = await axios.get(`${config.apiFileUrl}/knowledge_file/${fileId}`);
    return response.data;
};


export const createOrUpdateKnowledgeFile = async (
    knowledge_file: KnowledgeFile,
    upload_files: File[] | null
): Promise<{ task_id: string; message: string[] }> => {
    // 使用 FormData 构建请求数据
    const createFormData = (files: File[]) => {
        const formData = new FormData();
        formData.append('file_data', JSON.stringify(knowledge_file)); // 添加文件数据
        // 将每个文件添加到 FormData 对象
        files.forEach((file) => {
            formData.append('files', file);
        });
        return formData;
    };

    const uploadChunk = async (chunk: File[]): Promise<{ message: string[]; task_id: string }> => {
        const formData = createFormData(chunk);

        try {
            // 发起文件上传请求
            const response = await axios.post(
                `${config.apiFileUrl}/save_knowledge_files`,
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'},
                }
            );

            // 如果请求成功，返回响应数据
            return response.data; // 假设返回 { task_id, message }
        } catch (error) {
            // 捕获请求中的错误，进行处理
            console.error("重复文件:", error);
            // 返回错误响应，方便调用方处理
            return {task_id: "", message: []};
        }
    };


    const createPlaceholderFile = () => {
        return new File([''], 'placeholder.txt', {type: 'text/plain', lastModified: Date.now()});
    };

    // 如果没有文件，上传占位文件
    if (!upload_files || upload_files.length === 0) {
        return await uploadChunk([createPlaceholderFile()]);
    }

    const chunkSize = 5;
    let taskId: string | null = null;
    let allMessages: string[] = [];

    // 按 5 个文件为一组进行分块上传，并收集结果
    for (let i = 0; i < upload_files.length; i += chunkSize) {
        const chunk = upload_files.slice(i, i + chunkSize); // 取出一块
        try {
            const {task_id, message} = await uploadChunk(chunk); // 上传块并解构返回
            if (!taskId) {

                taskId = task_id; // 只需记录一次 task_id
            }
            allMessages = allMessages.concat(message); // 累积所有错误消息
        } catch (error) {
            console.error(`上传文件块失败: ${error}`);
            throw error; // 如果上传失败，抛出错误
        }
    }

    // 返回最终的 task_id 和所有错误消息
    return {task_id: taskId || '', message: allMessages};
};

export const deleteKnowledgeFiles = async (ids: number[]) => {
    if (ids.length == 0) return null;
    const response = await axios.delete(`${config.apiFileUrl}/delete_knowledge_files`, {data: ids});
    return response.data;
};

export const moveKnowledgeFiles = async (node_id: number, file_ids: number[]) => {
    if (file_ids.length == 0 || node_id == null) return null;
    // 构建请求数据对象
    const data = {
        node_id: node_id, // 树节点ID
        file_ids: file_ids // 文件ID列表
    };
    const response = await axios.post(`${config.apiFileUrl}/move_knowledge_files`, data);
    return response.data;
}