import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed, onBeforeMount} from 'vue';
import {useRoute} from 'vue-router';
import MainLayout from './layouts/MainLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import {useLocaleStore} from '@/stores/localStore';
import ElementPlusLocale from "@/setups/element-plus-setup";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const localeStore = useLocaleStore();

const elementPlusLocale = computed(() => (ElementPlusLocale(localeStore.getLocale)));

const route = useRoute();
const layout = computed(() => {
  if (!route.name) return null;
  if (route.name === 'login') {
    return AuthLayout;
  } else {
    return MainLayout;
  }
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { locale: elementPlusLocale.value }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(layout.value), null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})