<script setup lang="ts">
// import {useRouter} from 'vue-router';
import {onMounted} from "vue";
import router from "@/router";

// const router = useRouter();

defineProps({
  defaultActive: String
});


const intoFunction = async (name: string) => {
  switch (name) {
    case 'usermanage':
      await router.push('/usermanage');
      break;
    case 'prompt':
      await router.push('/prompt');
      break;
    case 'robot':
      await router.push('/robot');
      break;
    case 'test':
      await router.push('/test');
      break;
    case 'system':
      await router.push('/system');
      break;
    case 'llm_setup':
      await router.push('/llm_setup');
      break;
    case 'files':
      await router.push('/files');
      break;
    case 'vectorization':
      await router.push('/vectorization');
      break;
    case 'search':
      await router.push('/search');
      break;
    default:
      break;
  }
};

onMounted(() => {
  intoFunction('robot');
});

</script>

<template>
  <el-aside>
    <el-menu
        :default-openeds="['1','4']"
        :default-active="defaultActive"
        class="el-menu-custom"
    >
      <el-sub-menu index="1">
        <template #title>
          <span class="title icon-text-button">
            <font-awesome-icon icon="hand-spock" class="icon-text-button"/>
          {{ $t('message.menu-1') }}</span>
        </template>
        <el-menu-item index="1-1" @click="intoFunction('robot')">
          <template #title>
            <font-awesome-icon icon="robot" class="icon-text-button"/>
            <span>{{ $t('message.menu-11') }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="1-2" @click="intoFunction('prompt')">
          <template #title>
            <font-awesome-icon icon="chess-board" class="icon-text-button"/>
            <span>{{ $t('message.menu-12') }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="1-3" @click="intoFunction('test')">
          <template #title>
            <font-awesome-icon icon="list-check" class="icon-text-button"/>
            <span>{{ $t('message.menu-13') }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="4">
        <template #title>
          <span class="title icon-text-button">
            <font-awesome-icon icon="warehouse"  class="icon-text-button"/>
          {{ $t('message.menu-4') }}</span>
        </template>
        <el-menu-item index="4-1" @click="intoFunction('files')">
          <template #title>
            <font-awesome-icon icon="folder-tree" class="icon-text-button"/>
            <span>{{ $t('message.menu-41') }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="4-2" @click="intoFunction('vectorization')">
          <template #title>
            <font-awesome-icon icon="arrows-turn-to-dots" class="icon-text-button"/>
            <span>{{ $t('message.menu-42') }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="4-3" @click="intoFunction('search')">
          <template #title>
            <font-awesome-icon icon="list-check" class="icon-text-button"/>
            <span>{{ $t('message.menu-43') }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="2">
        <template #title>
          <span class="title icon-text-button">
          <font-awesome-icon icon="users" class="icon-text-button"/>
          {{ $t('message.menu-2') }}</span>
        </template>
        <el-menu-item index="2-1" @click="intoFunction('usermanage')">
          <template #title>
            <font-awesome-icon icon="user-gear" class="icon-text-button"/>
            <span>{{ $t('message.menu-21') }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="2-2">
          <template #title>
            <font-awesome-icon icon="address-book" class="icon-text-button"/>
            <span>{{ $t('message.menu-22') }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>
          <span class="title icon-text-button">
            <font-awesome-icon icon="gear" class="icon-text-button"/>
          {{ $t('message.menu-3') }}</span>
        </template>
        <el-menu-item index="3-1" @click="intoFunction('llm_setup')">
          <template #title>
            <font-awesome-icon icon="brain" class="icon-text-button"/>
            <span>{{ $t('message.menu-31') }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="3-2" @click="intoFunction('system')">
          <template #title>
            <font-awesome-icon icon="gears" class="icon-text-button"/>
            <span>{{ $t('message.menu-32') }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
    </el-menu>
  </el-aside>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.el-menu-custom {
  height: 100%;
  --el-menu-active-color: #{$table-header-text-color};
  --el-menu-border-color: Transparent;
  background-color: $menu-bg;
  --el-menu-bg-color: #{$menu-bg};
  --el-menu-hover-bg-color: #{$menu-hover-bg};
  border: 0;


  html.dark & {
    background-color: $menu-bg-dark;
    --el-menu-bg-color: #{$menu-bg-dark};
    --el-menu-hover-bg-color: #{$menu-hover-dark-bg};
  }
}

.el-menu-item.is-active {
  border-left: 5px solid $sk-border-color;
  border-right: 0;
  background-color: white;

  html.dark & {
    background-color: $main-background-dark;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: $table-header-text-color;
}

//.el-menu-custom :deep .el-menu-item.is-active,
//.el-menu-custom :deep .el-submenu.is-active {
//  border-right: 5px solid $sk-border-color ;
//}


</style>
