<template>
  <div>
    <el-container>
      <el-container class="login-bg">
        <el-aside class="login-pic">
        </el-aside>
        <el-main>
          <slot></slot> <!-- 登录表单 -->
        </el-main>
      </el-container>
      <el-footer class="footer">
        <div>{{ $t('message.footer') }}</div>
        <div class="language-sel">
          <el-segmented v-model="language" :options="languages" @change="changeLanguage"/>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup lang="ts">

import {onBeforeMount, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useLocaleStore} from '@/stores/localStore';

// 获取 i18n 实例
const {locale: i18nLocale} = useI18n();
const {t} = useI18n();

// 设置响应式的语言状态
const language = ref(t('language.zh'));
const languages = [t('language.zh'), t('language.en'), t('language.ja'), t('language.fr'), t('language.es')]

const localeStore = useLocaleStore();

const changeLanguage = () => {
  let temp: ('zh' | 'en' | 'fr' | 'ja' | 'es')

  switch (language.value) {
    case t('language.zh'):
      temp = "zh";
      break
    case t('language.en'):
      temp = "en";
      break
    case t('language.ja'):
      temp = "ja";
      break
    case t('language.fr'):
      temp = "fr"
      break
    case t('language.es'):
      temp = "es"
      break
    default:
      temp = "zh"
  }
  localeStore.setLocale(temp);
  i18nLocale.value = temp;
};

// 执行设置语言操作
onBeforeMount(() => {
  switch (localeStore.getLocale) {
    case "zh":
      language.value = t('language.zh');
      break
    case "en":
      language.value = t('language.en');
      break
    case "ja":
      language.value = t('language.ja');
      break
    case "fr":
      language.value = t('language.fr');
      break
    case "es":
      language.value = t('language.es');
      break
    default:
      language.value = t('language.zh');
  }
  changeLanguage();
});

</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/animation-bg.scss';

.footer {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  padding-top: 30px;
}


.login-pic {
  background-size: cover;
  background: url('@/assets/login-back.jpg') no-repeat center;
}

.el-container {
  background-color: transparent;
  min-width: 1000px;
  min-height: 800px;

  html.dark & {
    background-color: $chat-ai-pop;
  }
}

.el-main {
  padding: 0;
  min-width: 350px;
  min-height: 800px;

  html.dark & {
    background-color: transparent;
  }
}


.el-aside {
  width: 200px;
  background-color: transparent;
  min-height: 800px;
}


.el-footer {
  min-width: 350px;
  background-color: $footer-background-light;
  align-items: center;
  justify-content: right;
  border: 0;
  color: $footer-background-dark;

  html.dark & {
    color: $header-font-color-light;
    background-color: $enabled-dark;
    //background-color: rgba(red($input-background-dark), green($input-background-dark), blue($input-background-dark), 0.2);
  }

}


</style>