<script setup lang="ts">

</script>

<template>
<el-text>Search</el-text>
</template>

<style scoped lang="scss">

</style>