import {defineStore} from 'pinia';
import {
    createOrUpdateKnowledgeFile,
    deleteKnowledgeFiles,
    getKnowledgeFileById,
    getKnowledgeFiles,
    moveKnowledgeFiles
} from '@/services/fileService';
import {showNotification} from "@/services/tools";
import {KnowledgeFile} from "@/types/KnowledgeFile";

export const useFileStore = defineStore('fileStore', {
    state: () => ({
        knowledgeFileList: [] as KnowledgeFile[],
        knowledgeFileTotal: 0,
        knowledgeFile: {} as KnowledgeFile,
    }),
    actions: {async getFiles(search_data: any | null, node_id: number, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getKnowledgeFiles(search_data, node_id, page, pageSize);
                this.knowledgeFileList = response.files;
                this.knowledgeFileTotal = response.total;
                localStorage.setItem("file_page_size", pageSize.toString());

                return response.total;

            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }
                return 0;
            }
        },
        async getFileById(FileId: number) {
            try {
                this.knowledgeFile = await getKnowledgeFileById(FileId);
                return 0;
            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'InvalidInput':
                        showNotification('error', '查询', "查询参数无效");
                        break;
                    case 'NotFoundFile':
                        showNotification('error', '查询', "无数据");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '未知状态', error.response.data.detail);
                        break;
                }

            }
        },
        async createOrUpdateFile(file: KnowledgeFile, upload_files: File[] | null) {
            try {
                const response = await createOrUpdateKnowledgeFile(file, upload_files);
                return response.message
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async deleteFiles(file_ids: number[]) {
            const response = await deleteKnowledgeFiles(file_ids);
            localStorage.setItem("cur_del_files_count", response.message.length);
            localStorage.setItem("cur_del_task", response.task_id);
            return response.message.length;
        },

        async moveFiles(node_id: number, file_ids: number[]) {
            return await moveKnowledgeFiles(node_id, file_ids)
        }

    },
});