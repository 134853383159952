<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {Prompt, Promptitems} from "@/types/Prompt" // 导入 User 类型定义
import {Robot} from "@/types/Robot"
import {usePromptStore} from "@/stores/promptStore" // 导入用户存储
import {showNotification} from "@/services/tools"
import {useRobotStore} from "@/stores/robotStore";

// 使用用户存储和国际化
const promptStore = usePromptStore() // 使用用户存储
const robotStore = useRobotStore()
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newPromptMessage = ref<string>('') // 新模板信息提示
const promptForm = ref<FormInstance>() // 模板表单实例
const promptDialog = ref(false) // 创建对话框显示状态


// 定义响应式数据
const options = ref<Robot[]>([])
const robot = ref<Robot>({} as Robot)

const prompt = ref<Prompt>({} as Prompt) // 创建用户对象
const PromptData = ref({ // 模板数据对象
  name: '',
  description: '',
  system_role_content: '',
  robot_id: -1,
  command: '',
  output_parser: '',
  publish: false,
  prompt_items: [
    {item_id: -1, prompt_id: -1, item_sort: 1, item_name: '', item_intro: '', item_default: ''} as Promptitems  // 初始时包含一个空项目
  ],
  robot: {
    robot_id: -1,
    name: '',
    description: '',
    system_role_content: '',
    command: '',
    output_parser: '',
    publish: false
  } as Robot
})
// 初始化模板数据
const defaultPromptData = {
  name: '',
  description: '',
  system_role_content: '',
  robot_id: -1,
  command: '',
  output_parser: '',
  publish: false,
  prompt_items: [
    {item_id: -1, prompt_id: -1, item_sort: 1, item_name: '', item_intro: '', item_default: ''} as Promptitems  // 初始时包含一个空项目
  ],
  robot: {
    robot_id: -1,
    name: '',
    description: '',
    system_role_content: '',
    command: '',
    output_parser: '',
    publish: false
  } as Robot
}
// 定义一个默认的Robot对象
const defaultRobot: Robot = {
  rb_id: -1,
  name: '',
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  create_datetime: null,
  modify_datetime: null
};

// 定义组件的 props 和 emits
const props = defineProps({ // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  prompt_info: {
    type: Object as () => Prompt,
    default: null
  }
})
const emit = defineEmits(["refreshList", "update:isShow"]) // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  promptDialog.value = val // 设置对话框显示状态
  newPromptMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.userinfo 的变化并初始化相关状态
watch(() => props.prompt_info, (newVal) => {
  if (newVal) {
    prompt.value = newVal;
    ini_form(prompt.value)
  } else {
    prompt.value = {} as Prompt;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const promptRules = computed(() => ({
  name: [{required: true, max: 50, message: t('prompt.name-check'), trigger: 'blur'}], // 昵称验证规则
  description: [{required: true, max: 100, message: t('prompt.description-check'), trigger: 'blur'}], // 登录名验证规则
}))


// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    promptDialog.value = false // 隐藏对话框
    newPromptMessage.value = ""
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件

  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

// 上传用户信息
const savePrompt = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;

  try {
    const valid = await formEl.validate(); // 验证表单

    if (valid) {
      setPromptValues(PromptData.value); // 设置 prompt 的值
      newPromptMessage.value = ""; // 清空提示信息
      await promptStore.createOrUpdatePrompt(prompt.value); // 保存模板
      PromptData.value = {...defaultPromptData} //清空PromptData数据
      newPromptMessage.value = ""
      refreshFatherList() // 刷新父组件列表
      await closeDialog() // 关闭对话框
      showNotification('success', t('message.success'), t('prompt.new-success-info')) // 显示成功通知
    }
  } catch (error: any) {
    let detail = "null"
    newPromptMessage.value = "";
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'PromptNameExisted':
        newPromptMessage.value = t('prompt.name-exist'); // 注册名已存在
        break;
      case 'MissingPromptID':
        newPromptMessage.value = "任务ID不能为空"; // 注册名已存在
        break;
      case 'PromptNotFound':
        newPromptMessage.value = "任务数据找不到"; // 未知错误
        break;
      case 'null':
        break;
      default:
        showNotification('error', '服务端错误', error.response.data.detail);
        break;
    }
  }

};

// 帮助函数来设置 prompt 的值
const setPromptValues = (data: typeof PromptData.value) => {
  prompt.value.name = data.name; // 设置模板名称
  prompt.value.description = data.description; // 设置模板描述
  prompt.value.system_role_content = data.system_role_content; // 设置模板系统角色内容
  prompt.value.rb_id = data.robot_id; // 设置机器人 ID
  prompt.value.command = data.command; // 设置模板命令
  prompt.value.output_parser = data.output_parser; // 设置模板输出解析器
  prompt.value.publish = data.publish; // 设置模板发布状态
  prompt.value.prompt_items = data.prompt_items; // 设置模板项目
};

const ini_form = async (prompt: Prompt) => {
  if (prompt) {
    PromptData.value.name = prompt.name || '' // 设置用户头像 URL
    PromptData.value.description = prompt.description || ''// 设置用户昵称
    PromptData.value.command = prompt.command || '' // 设置用户登录名
    PromptData.value.system_role_content = prompt.system_role_content || '' // 设置用户密码
    PromptData.value.robot_id = prompt.rb_id || -1
    PromptData.value.output_parser = prompt.output_parser || ''// 设置用户手机
    PromptData.value.publish = prompt.publish || false //设置管发布状态
    PromptData.value.prompt_items = prompt.prompt_items || [{
      item_id: -1,
      prompt_id: -1,
      item_sort: 1,
      item_name: '（无）',
      item_intro: '',
      item_default: ''
    }] // 设置项目
    if (prompt.rb_id && prompt.rb_id != -1) {
      await robotStore.getRobotById(prompt.rb_id || -1)
      robot.value = robotStore.robot
    } else {
      robot.value = defaultRobot
    }

  }
}

const addItem = () => {
  if (PromptData.value.prompt_items.length < 5) {
    newPromptMessage.value = ""
    PromptData.value.prompt_items.push({
      item_id: -1,
      prompt_id: -1,
      item_sort: PromptData.value.prompt_items.length + 1,
      item_name: '',
      item_intro: '',
      item_default: ''
    });
  } else {
    newPromptMessage.value = "最多只能添加5项";
  }
};

const removeItem = (index: number) => {
  newPromptMessage.value = ""
  PromptData.value.prompt_items.splice(index, 1);
};

// 获取选项数据的函数
const fetchOptions = async (robot_id: number) => {
  try {
    if (!robot_id) return
    if (robot_id == -1) {
      await robotStore.getRobotsSummary()
      options.value = [defaultRobot, ...robotStore.robotSummary]
      PromptData.value.robot_id = -1
      PromptData.value.system_role_content = ""
      return
    } else {
      for (const item of options.value) {
        if (item.rb_id == robot_id) {
          PromptData.value.robot = item as Robot
          PromptData.value.robot_id = robot_id
          PromptData.value.system_role_content = item.system_role_content
          break
        }
      }
    }

  } catch (error) {
    console.error('Error fetching options:', error);
  }
};

// 组件挂载时调用
onMounted(() => {
  fetchOptions(-1);
});

</script>

<template>
  <el-dialog :visible="promptDialog" @close="closeDialog" :title="$t('prompt.new-title')" width="1100px"
             align-center center draggable :close-on-click-modal="false">
    <el-form ref="promptForm" :model="PromptData" :rules="promptRules" label-width="100px" :label-position="'right'"
    >

      <el-row>
        <el-alert v-if="newPromptMessage" type="warning" :description="newPromptMessage" :closable="false" show-icon
                  :effect="'light'" class="reg-alert">
        </el-alert>

        <el-col :span="12">
          <div class="col-left">
            <el-form-item :label="$t('prompt.name')" prop="name">
              <el-input v-model="PromptData.name"
                        :placeholder="$t('prompt.name-tip')"
                        clearable
                        show-word-limit
                        maxlength="50"
                        style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('prompt.description')" prop="description">
              <el-input
                  v-model="PromptData.description"
                  :placeholder="$t('prompt.description-tip')"
                  clearable
                  type="textarea"
                  :rows="4"
                  show-word-limit
                  maxlength="100"></el-input>
            </el-form-item>
            <el-form-item :label="$t('message.robot')" prop="system_role_content">
              <el-select
                  v-model="robot"
                  value-key="rb_id"
                  :placeholder="$t('robot.select')"
                  style="width: 100%"
                  :change="fetchOptions(Number(robot.id))"
              >
                <el-option
                    v-for="item in options"
                    :key="item.rb_id"
                    :label="item.name"
                    :value="item"
                />
              </el-select>
              <el-text>
                <div v-if="robot.system_role_content" style="margin-top: 10px">
                  <el-text class="multi-line-text">{{ robot.system_role_content }}</el-text>
                </div>
              </el-text>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="col-right">
            <el-form-item :label="$t('prompt.publish')" prop="publish">
              <el-switch
                  v-model="PromptData.publish"
                  inline-prompt
                  :active-text="$t('message.yes')"
                  :inactive-text="$t('message.no')"
              />
            </el-form-item>
            <el-form-item :label="$t('prompt.command')" prop="command">
              <el-input v-model="PromptData.command"
                        :placeholder="$t('prompt.command-tip')"
                        clearable
                        type="textarea"
                        :rows="4"
                        show-word-limit
                        maxlength="400"></el-input>
            </el-form-item>
            <el-form-item :label="$t('prompt.output_parser')" prop="output_parser">
              <el-input v-model="PromptData.output_parser"
                        :placeholder="$t('prompt.output_parser-tip')"
                        clearable
                        type="textarea"
                        :rows="4"
                        show-word-limit
                        maxlength="200"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-divider>
        <div class="divider_text">{{ $t('prompt.prompt_items') }}</div>
      </el-divider>
      <div class="div_right">
        <el-button @click="addItem" type="primary" plain round>
          <font-awesome-icon icon="plus" class="icon-text-button"/>
          {{ $t('prompt.item_add') }}
        </el-button>
      </div>
      <el-row>
        <el-col :span="24">
          <el-form-item style="width: 100%">
            <div v-for="(item, index) in PromptData.prompt_items" :key="index" style="margin-top: 15px;width: 100%">
              <el-row>
                <el-row>
                  <el-col :span="3">
                    <el-select v-model="item.item_sort" :placeholder="$t('prompt.item_sort')">
                      <el-option label="1" value=1></el-option>
                      <el-option label="2" value=2></el-option>
                      <el-option label="3" value=3></el-option>
                      <el-option label="4" value=4></el-option>
                      <el-option label="5" value=5></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="20">
                    <el-input v-model="item.item_name"
                              :placeholder="$t('prompt.item_name')"
                              clearable
                              show-word-limit
                              maxlength="50"
                    ></el-input>
                  </el-col>
                  <el-col :span="1">
                    <el-button type="danger" @click="removeItem(index)" plain circle>
                      <font-awesome-icon icon="trash-can"/>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-input v-model="item.item_intro"
                              :placeholder="$t('prompt.item_intro')"
                              clearable
                              type="textarea"
                              :rows="4"
                              show-word-limit
                              maxlength="100"></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-input v-model="item.item_default"
                              :placeholder="$t('prompt.item_default')"
                              clearable
                              type="textarea"
                              :rows="4"
                              show-word-limit
                              maxlength="100"></el-input>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div>
        <el-button type="primary" @click="savePrompt(promptForm)">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.move') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.col-right {
  width: 100%;
}

.col-left {
  width: 100%;
}

.div_right {
  justify-content: flex-end;
  width: 99%;
  display: flex;
}

.divider_text {
  font-weight: bold;
  color: $table-header-text-color;
}

/* 注册专用警告框样式 */
.reg-alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}

.el-row {
  display: flex;
  align-items: flex-start;
  padding: 0;
}

.el-col {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 5px;
}


.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  margin-right: 5px;

}


</style>
