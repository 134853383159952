import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "div-wrapper" }
const _hoisted_2 = { class: "icon-text-button-up" }
const _hoisted_3 = { class: "icon-text-button-up" }

import LoginForm_up from '@/components/LoginForm-up.vue';
import LoginForm_wechat from '@/components/LoginForm-wechat.vue';
import {ref} from 'vue';
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const {t} = useI18n();
const activeName = ref('userPass')



return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: activeName.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeName).value = $event)),
      "tab-position": "bottom",
      stretch: "",
      class: "custom-tab-height"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { name: "userPass" }, {
          label: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                icon: "user-group",
                size: "2xl",
                style: {"color":"#256698","margin-bottom":"15px"}
              }),
              _createElementVNode("span", null, _toDisplayString(_unref(t)('message.account')), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(LoginForm_up)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, { name: "wechat" }, {
          label: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: "comments",
                size: "2xl",
                style: {"color":"#1a8968","margin-bottom":"15px"}
              }),
              _createElementVNode("span", null, _toDisplayString(_unref(t)('message.wechat')), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(LoginForm_wechat)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})