import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title icon-text-button" }
const _hoisted_2 = { class: "title icon-text-button" }
const _hoisted_3 = { class: "title icon-text-button" }
const _hoisted_4 = { class: "title icon-text-button" }

import {onMounted} from "vue";
import router from "@/router";

// const router = useRouter();


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarMenu',
  props: {
  defaultActive: String
},
  setup(__props) {

// import {useRouter} from 'vue-router';



const intoFunction = async (name: string) => {
  switch (name) {
    case 'usermanage':
      await router.push('/usermanage');
      break;
    case 'prompt':
      await router.push('/prompt');
      break;
    case 'robot':
      await router.push('/robot');
      break;
    case 'test':
      await router.push('/test');
      break;
    case 'system':
      await router.push('/system');
      break;
    case 'llm_setup':
      await router.push('/llm_setup');
      break;
    case 'files':
      await router.push('/files');
      break;
    case 'vectorization':
      await router.push('/vectorization');
      break;
    case 'search':
      await router.push('/search');
      break;
    default:
      break;
  }
};

onMounted(() => {
  intoFunction('robot');
});


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_aside = _resolveComponent("el-aside")!

  return (_openBlock(), _createBlock(_component_el_aside, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu, {
        "default-openeds": ['1','4'],
        "default-active": __props.defaultActive,
        class: "el-menu-custom"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_sub_menu, { index: "1" }, {
            title: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "hand-spock",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.menu-1')), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                index: "1-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (intoFunction('robot')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "robot",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-11')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, {
                index: "1-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (intoFunction('prompt')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "chess-board",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-12')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, {
                index: "1-3",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (intoFunction('test')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "list-check",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-13')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_sub_menu, { index: "4" }, {
            title: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "warehouse",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.menu-4')), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                index: "4-1",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (intoFunction('files')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "folder-tree",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-41')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, {
                index: "4-2",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (intoFunction('vectorization')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "arrows-turn-to-dots",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-42')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, {
                index: "4-3",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (intoFunction('search')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "list-check",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-43')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_sub_menu, { index: "2" }, {
            title: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "users",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.menu-2')), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                index: "2-1",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (intoFunction('usermanage')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "user-gear",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-21')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "2-2" }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "address-book",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-22')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_sub_menu, { index: "3" }, {
            title: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "gear",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.menu-3')), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                index: "3-1",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (intoFunction('llm_setup')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "brain",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-31')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, {
                index: "3-2",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (intoFunction('system')))
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "gears",
                    class: "icon-text-button"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-32')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["default-active"])
    ]),
    _: 1
  }))
}
}

})