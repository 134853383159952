// config.ts

// 定义配置接口，以确保类型正确性
interface Config {
    apiUserUrl: string;
    apiPromptUrl:string;
    apiRobotUrl:string;
    apiLlmUrl:string;
    apiSysUrl:string;
    apiFileUrl:string;
    encryptionKey: string;
    apiKey: string;

}

// 确保环境变量存在并且类型正确
const config: Config = {
    apiUserUrl: process.env.VUE_APP_SK_USERS_API_URL || 'http://localhost:9000',
    apiPromptUrl: process.env.VUE_APP_SK_PROMPTS_API_URL || 'http://localhost:9000',
    apiRobotUrl: process.env.VUE_APP_SK_ROBOTS_API_URL || 'http://localhost:9000',
    apiLlmUrl: process.env.VUE_APP_SK_LLM_API_URL || 'http://localhost:9000',
    apiSysUrl: process.env.VUE_APP_SK_SYS_API_URL ||'',
    apiFileUrl: process.env.VUE_APP_SK_FILE_API_URL ||'',
    encryptionKey: process.env.VUE_APP_ENCRYPTION_KEY || 'dont tell anyone',
    apiKey: process.env.VUE_APP_SK_API_KEY || 'dont tell anyone',
};

// 导出配置对象
export default config;
