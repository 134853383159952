<!-- views/Login.vue -->
<template>
  <div class="div-wrapper">
    <el-tabs  v-model="activeName" tab-position="bottom" stretch class="custom-tab-height">
       <el-tab-pane  name="userPass">
        <template #label>
          <div class="icon-text-button-up">
            <font-awesome-icon icon="user-group" size="2xl" style="color: #256698;margin-bottom: 15px;" />
            <span>{{ t('message.account') }}</span>
          </div>
        </template>
        <LoginForm_up></LoginForm_up>
      </el-tab-pane>
      <el-tab-pane name="wechat">
        <template #label>
          <div class="icon-text-button-up">
            <font-awesome-icon icon="comments" size="2xl" style="color: #1a8968;margin-bottom: 15px;" />
            <span>{{ t('message.wechat') }}</span>
          </div>
        </template>
        <LoginForm_wechat></LoginForm_wechat>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script setup lang="ts">
import LoginForm_up from '@/components/LoginForm-up.vue';
import LoginForm_wechat from '@/components/LoginForm-wechat.vue';
import {ref} from 'vue';
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const activeName = ref('userPass')


</script>

<style scoped>
.div-wrapper {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100%; /* 占满整个视口高度 */
  margin: 0; /* 设置外边距，使其上下居中 */
}

.custom-tab-height {
  --el-tabs-header-height: 80px;
  padding: 50px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}


</style>