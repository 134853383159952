<script setup lang="ts">

</script>

<template>
  <el-text>Vectorization</el-text>
</template>

<style scoped lang="scss">

</style>