import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs';
import ja from 'element-plus/dist/locale/ja.mjs'
import fr from 'element-plus/dist/locale/fr.mjs';
import es from 'element-plus/dist/locale/es.mjs'
import 'element-plus/dist/index.css';

const ElementPlusLocale = (locale:  'zh' | 'en' | 'fr' | 'ja' | 'es') => {
    switch (locale) {
        case 'zh':
            return zhCn;
        case 'en':
            return en;
        case 'ja':
            return ja;
        case 'fr':
            return fr;
        case 'es':
            return es;
        default:
            return en;
    }
};

export default ElementPlusLocale
