<template>
  <el-row :gutter="20">
    <el-col :span="3" class="session-list">

      <el-button round type="primary" style="width: 130px;" @click="new_session(false)">
        <font-awesome-icon icon="comment-dots" class="icon-text-button"/>
        开始新话题
      </el-button>
      <el-divider/>
      <div style="padding-right: 10px; width: 160px;align-content: center">
        <el-scrollbar :height="contentHeight">
          <el-radio-group
              v-model="chat_session"
              v-for="(sess, index) in llmStore.chat_session"
              :key="index"
              @change="session_selected">
            <el-tooltip
                :content=sess.session_name
                placement="left"
            >
              <el-radio-button :value="sess.session_id">
                <el-text line-clamp="1" class="session-text">
                  {{ sess.session_name }}
                </el-text>
              </el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </el-scrollbar>
      </div>
    </el-col>
    <el-col :span="16">
      <div style="padding-left: 15px;padding-right: 5px;height: 100%">
        <chatForm ref="chatFormRef" @messagesResponse="handleDisplayMsgs"/>
      </div>
    </el-col>
    <el-col :span="5" class="test-right">
      <el-calendar
          ref="calendar"
          :model-value="curDate"
          @input="handleSelect"
      >
        <template #header="{ date }">
          <span style="font-size: 13px;align-content: center">{{ date }}</span>
          <el-button-group>
            <el-button size="small" round type="primary" style="width:45px" @click="selectDate('prev-month')">
              {{ $t('message.prev-month') }}
            </el-button>
            <el-button size="small" round type="primary" style="width:40px" @click="selectDate('today')">
              {{ $t('message.today') }}
            </el-button>
            <el-button size="small" round type="primary" style="width:45px" @click="selectDate('next-month')">
              {{ $t('message.next-month') }}
            </el-button>
          </el-button-group>
        </template>
      </el-calendar>
      <div class="right_card">
        <el-descriptions
            :title="$t('chat.context')"
            :column="2"
            border
        >
          <el-descriptions-item :label="$t('chat.topic')" span="2" width="100px" label-align="center">

            <el-text line-clamp="1" size="small">
              {{ context_topic }}
            </el-text>

          </el-descriptions-item>
          <el-descriptions-item :label="$t('message.robot')" span="2" label-align="center">
            <el-tooltip
                :content=context_robot
                placement="top-end"
            >
              <el-text line-clamp="2" size="small">
                {{ context_robot }}
              </el-text>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('chat.sys-human')" span="2" label-align="center">
            <el-tooltip
                :content=context_sys_human
                placement="top-end"
            >
              <el-text line-clamp="2" size="small">
                {{ context_sys_human }}
              </el-text>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="right_card">
        <el-descriptions
            :title="$t('chat.current-question')"
            :column="2"
            border
        >
          <el-descriptions-item :label="$t('chat.relevance')" span="2" width="100px" label-align="center">

            <el-text line-clamp="1" size="small">
              {{ cur_relevance ? $t("message.yes") : $t("message.no") }}
            </el-text>

          </el-descriptions-item>

          <el-descriptions-item :label="$t('chat.topic')" span="2" label-align="center">

            <el-text line-clamp="1" size="small">
              {{ cur_topic }}
            </el-text>

          </el-descriptions-item>
          <el-descriptions-item :label="$t('message.robot')" span="2" width="50px" label-align="center">
            <el-tooltip
                :content=cur_robot
                placement="top-end"
            >
              <el-text line-clamp="2" size="small">
                {{ cur_robot }}
              </el-text>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('chat.sys-human')" span="2" width="50px" label-align="center">
            <el-tooltip
                placement="top-end"
                :content=cur_sys_human
            >
              <el-text line-clamp="2" size="small">
                {{ cur_sys_human }}
              </el-text>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import chatForm from "@/components/ChatForm.vue"
import {ChatMessage} from '@/types/LLM';
import {onMounted, ref, watch} from "vue";
import {CalendarDateType, CalendarInstance, ElScrollbar} from 'element-plus'
import {useLLMStore} from "@/stores/llmStore";
import {useUserStore} from "@/stores/userStore";
import {autoHeight} from "@/services/tools";

const curDate = ref<Date>(new Date());
const cur_topic = ref("")
const context_topic = ref("")
const cur_relevance = ref(false)
const cur_sys_human = ref("")
const context_sys_human = ref("")
const cur_robot = ref("")
const context_robot = ref("")
const calendar = ref<CalendarInstance>()

// 为 chatFormRef 指定正确的类型
const chatFormRef = ref<InstanceType<typeof chatForm> | null>(null);
const llmStore = useLLMStore();
const userStore = useUserStore();
const chat_session = ref('')

const contentHeight = autoHeight(140);

const session_selected = async () => {

  // 查找与该 session_id 对应的会话
  const selectedSession = llmStore.chat_session.find(sess => sess.session_id === chat_session.value);

  if (selectedSession) {
    // 获取 session_id 和 session_name
    const sessionId = selectedSession.session_id;
    const sessionName = selectedSession.session_name;
    if (chatFormRef.value) {
      llmStore.chat_history.length = 0
      llmStore.streamAnswer.length = 0
      await llmStore.loadChaSessionList(chat_session.value, null, null)

      chatFormRef.value.setSession(sessionId, sessionName)
      chatFormRef.value.setMessages(llmStore.chat_history)
    }
  }
}

const selectDate = (val: CalendarDateType) => {
  if (!calendar.value) return
  calendar.value.selectDate(val)
}

const new_session = async (load: boolean) => {
  if (llmStore.chat_history.length == 0 && !load) return
  if (chatFormRef.value) {
    llmStore.chat_history.length = 0
    llmStore.streamAnswer.length = 0

    if (!load) {
      // await handleSelect(new Date)
      llmStore.chat_session.unshift({
        "session_id": Date.now().toString() + "U" + userStore.loginUser!.user_id?.toString(),
        "session_name": "新话题" + Date.now().toString().slice(-6)
      })
      chatFormRef.value.setSession(llmStore.chat_session[0].session_id,
          llmStore.chat_session[0].session_name)
      chat_session.value = llmStore.chat_session[0].session_id
      llmStore.chat_history.length = 0
      llmStore.streamAnswer.length = 0
      chatFormRef.value.setMessages(llmStore.chat_history)
    } else {
      llmStore.chat_session.length = 0
      llmStore.chat_session.push({
        "session_id": Date.now().toString() + "U" + userStore.loginUser!.user_id?.toString(),
        "session_name": "新话题" + Date.now().toString().slice(-6)
      })
      chatFormRef.value.setSession(llmStore.chat_session[0].session_id,
          llmStore.chat_session[0].session_name)
      chat_session.value = llmStore.chat_session[0].session_id
      llmStore.chat_history.length = 0
      llmStore.streamAnswer.length = 0
      chatFormRef.value.setMessages(llmStore.chat_history)
    }
  }
}

const handleSelect = async (date: Date) => {
  try {
    if (date > new Date()) date = new Date()
    curDate.value = date
    if (chatFormRef.value) {
      await llmStore.loadChatList(date, date)
      if (llmStore.chat_history.length > 0) {
        chatFormRef.value.setSession(llmStore.chat_session[0].session_id, llmStore.chat_session[0].session_name);
        chat_session.value = ""
      } else {
        chatFormRef.value.setSession("", "")
        chat_session.value = ""
      }

      // 使用 setMessages 方法
      chatFormRef.value.setMessages(llmStore.chat_history);

    }
  } catch (error) {
    console.log(error)
  }
};

const handleDisplayMsgs = (msgs: ChatMessage[]) => {
  if (msgs.length < 4) {
    context_topic.value = msgs[0].topic
    context_robot.value = msgs[0].robot_content
    context_sys_human.value = msgs[0].sys_content
  } else {
    context_topic.value = msgs[msgs.length - 4].topic
    context_robot.value = msgs[msgs.length - 4].robot_content
    context_sys_human.value = msgs[msgs.length - 4].sys_content
  }

  cur_topic.value = msgs[msgs.length - 2].topic
  cur_robot.value = msgs[msgs.length - 2].robot_content
  cur_relevance.value = msgs[msgs.length - 2].relevance
  cur_sys_human.value = msgs[msgs.length - 2].sys_content

}

watch(curDate, (newDate) => {
  if (newDate && newDate > new Date()) {
    curDate.value = new Date(); // Automatically set to current time if selected date is in the future
  }
});

// 组件挂载后执行的操作
onMounted(async () => {
  await new_session(true)
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.session-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  border-right: 1px solid $chat-pop-dark
}

.session-text {
  text-align: left;
}

.right_card {
  padding: 5px;
  margin-top: 10px;
}

.el-radio-button :deep(.el-radio-button__inner) {
  margin-top: 5px;
  border: 0;
  border-radius: 15px !important;
  width: 130px;
  --el-radio-button-checked-bg-color: #eebe77;

  html.dark & {
    --el-radio-button-checked-bg-color: #337ecc
  }
}

.el-descriptions {
  --el-descriptions-table-border: 1.5px solid #a0cfff;

  html.dark & {
    --el-descriptions-table-border: 1.5px solid #337ecc;
  }
}

.el-calendar {
  --el-calendar-cell-width: 28px;
  --el-calendar-border: 0;
  --el-calendar-selected-bg-color: #d9ecff;
  border-radius: 15px;

  html.dark & {
    --el-calendar-selected-bg-color: #337ecc;
    --el-calendar-border: 0;
  }
}

.el-calendar :deep(.el-calendar__body) {
  padding: 5px 20px 15px 20px;
  border-radius: 0 0 15px 15px;
  background-color: $table-row-hover-bg-color;

  html.dark & {
    background-color: $header-background-dark;
  }
}

.el-calendar :deep(.el-calendar__header) {
  padding: 15px;
  border-radius: 15px 15px 0 0;
  background-color: $enabled-dark;

  html.dark & {
    background-color: $main-background-dark;
  }
}

.el-calendar :deep(.el-calendar-day) {
  font-size: 13px;
}

.el-text {
  white-space: normal; /* 确保文本能够换行 */
  word-break: break-word; /* 防止长单词溢出 */
}

.test-right {
  background-color: $input-background-light;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px;

  html.dark & {
    background-color: $footer-font-color-light;
  }
}

</style>