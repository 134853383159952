// 文件位置：src/stores/localStore.ts

import { defineStore } from 'pinia';

export const useLocaleStore = defineStore('localeStore', {
    state: () => ({
        locale: (localStorage.getItem('locale') as 'zh' | 'en' | 'fr' | 'ja' | 'es') || 'zh'
    }),
    getters: {
        getLocale: (state) => state.locale
    },
    actions: {
        setLocale(newLocale: 'zh' | 'en' | 'fr' | 'ja' | 'es') {
            this.locale = newLocale;
            localStorage.setItem('locale', newLocale);
        }
    }
});
