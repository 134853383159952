import {defineStore} from 'pinia';
import {Prompt} from '@/types/Prompt';
import {showNotification} from '@/services/tools'
import {
    createOrUpdatePrompt,
    deletePrompts,
    getPromptById,
    getPrompts,
    getPromptsSummary
} from '@/services/promptService';

export const usePromptStore = defineStore('promptStore', {
    state: () => ({
        promptList: [] as Prompt[],
        promptSummary: [] as any[],
        promptTotal: 0,
        prompt: {} as Prompt,
    }),
    actions: {
        async getPrompts(keyword: string | null, publish: number, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getPrompts(keyword, publish, page, pageSize);
                this.promptList = response.prompts;
                this.promptTotal = response.total;
                localStorage.setItem("prompt_page_size", pageSize.toString());
                return response.total;
            } catch (error: any) {
                let detail = "null"
                if (error.response) {
                    detail = error.response.data.detail;
                }
                switch (detail) {
                    case 'InvalidInput':
                        showNotification('warning', '任务查询', "输入参数无效");
                        break;
                    case 'null':
                        break;
                    default:
                        showNotification('error', '服务器问题', error.response.data.detail);
                        break;
                }
                return 0;
            }
        },
        async getPromptById(promptId: number) {
            this.prompt = await getPromptById(promptId);
        },
        async createOrUpdatePrompt(prompt: Prompt) {
            const response = await createOrUpdatePrompt(prompt);
            return response.message
        },
        async del_Prompts(prompt_ids: number[]) {
            return await deletePrompts(prompt_ids);
        },
        async getPromptSummary() {
            this.promptSummary = await getPromptsSummary();
        },
    },
});