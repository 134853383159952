<script setup lang="ts">

</script>

<template>
 <div class="login-form">
          Config
        </div>
</template>

<style scoped>
.login-form {
  width: 300px;
  height: 550px;
  justify-content: center; /* 水平居中 */
  margin: 0 auto; /* 设置外边距，使其上下居中 */
}
</style>