import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = { class: "gray-background" }
const _hoisted_3 = { class: "div_col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "div_col" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_10 = { class: "title_text" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "status-dot green"
}
const _hoisted_15 = {
  key: 1,
  class: "status-dot red"
}
const _hoisted_16 = ["innerHTML"]

import {onMounted, reactive, ref} from 'vue';
import {ElTable} from 'element-plus';
import {Prompt} from '@/types/Prompt';
import {autoHeight, formatDate, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {usePromptStore} from "@/stores/promptStore";
import {useI18n} from "vue-i18n";
import NewPrompt from "@/components/NewPrompt.vue";

// 使用国际化功能

export default /*@__PURE__*/_defineComponent({
  __name: 'prompt_lib_m',
  setup(__props) {

const {t} = useI18n();

// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(20);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<Prompt[]>([]);  // 表格数据
const publish = ref<string>("-1");  // 发布状态

const tableHeight = autoHeight(190);  // 表格高度自适应
const promptStore = usePromptStore();  // 使用用户存储
const multipleSelection = ref<Prompt[]>([]);  // 多选用户
const promptTable = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const showDialog = ref(false);  // 控制新增模板对话框显示
const showEditDialog = ref(false);  // 控制编辑模板对话框显示
const cur_prompt = ref<Prompt>();  // 当前操作的用户
const searchData = reactive({
  keyword: ''  // 搜索关键字
});


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await promptStore.getPrompts(searchData.keyword, Number(publish.value), currentPage.value, val);
    pageSize.value = val;
    tableData.value = promptStore.promptList;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await promptStore.getPrompts(searchData.keyword, Number(publish.value), val, pageSize.value);
    currentPage.value = val
    tableData.value = promptStore.promptList
  } finally {
    loading.close();
  }
};

/**
 * 搜索用户
 * @param {string} searchData - 搜索关键字
 */
const searchPrompt = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await promptStore.getPrompts(searchData, Number(publish.value), 1, pageSize.value);
    tableData.value = promptStore.promptList;
  } finally {
    loading.close();
  }
};

/**
 * 处理表格行选择变化
 * @param {User[]} val - 选中的用户列表
 */
const handleSelectionChange = (val: Prompt[]) => {
  multipleSelection.value = val;
};

/**
 * 删除用户
 * @param {number} cur_row - 当前操作的行号
 */
const promptsDelete = async (cur_row: number) => {
  const promptIds: number[] = [];
  try {
    // 检查是否有选中的用户
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.del-last-one'));
      return;
    }

    // 如果传入的行号有效添加到 promptIds
    if (cur_row !== -1) {
      promptIds.push(cur_row);
    } else {
      promptIds.push(...multipleSelection.value.map(prompt => prompt.prompt_id!));
    }

    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    // 执行删除操作
    const target = document.querySelector('.el-table') as HTMLElement;
    const loading = showLoading(t, target);
    await promptStore.del_Prompts(promptIds);

    tableData.value = tableData.value.filter(item =>
        !promptIds.includes(item.prompt_id!)
    );
    totalItems.value = totalItems.value - promptIds.length;
    const count = promptIds.length;
    showNotification("success", t('message.del-success-title'), t('prompt.del-prompt-success', {count}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'MissingPromptID':
        showNotification("success", t('message.del-success-title'), "任务ID不能为空");
        break;
      case 'null':
        break;
      default:
        showNotification('error', t('message.del-success-title'), error.response.data.detail);
        break;
    }
  }
};

/**
 * 显示对话框
 * @param {boolean} new_prompt - 是否是新增用户
 * @param {User | null} prompt_data - 当前用户数据
 */
const show_dialog = async (new_prompt: boolean, prompt_data: Prompt | null) => {
  if (new_prompt) {
    showDialog.value = true;
    showEditDialog.value = false;
  } else {
    showDialog.value = false;
    showEditDialog.value = true;
    if (prompt_data) {
      cur_prompt.value = prompt_data;
    }
  }
};

// 文本格式化函数
function formatText(text: string): string {
  if (!text) return '';
  // 将换行符替换为 <br> 标签
  return text.replace(/\n/g, '<br>');
}

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("prompt_page_size");
  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 20,
          class: "left"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_radio_group, {
                modelValue: publish.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((publish).value = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (searchPrompt(searchData.keyword)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.all'),
                    value: "-1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.enable'),
                    value: "1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.disable'),
                    value: "0",
                    border: ""
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                style: {"height":"25px"}
              }),
              _createVNode(_component_el_form, {
                ref: "searchForm",
                model: searchData,
                inline: true,
                class: "search-form",
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "searchKey",
                    class: "keyword-input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: searchData.keyword,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchData.keyword) = $event)),
                        placeholder: _ctx.$t('user.SearchKey'),
                        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (searchPrompt(searchData.keyword)), ["enter"])),
                        style: {"width":"200px"},
                        clearable: ""
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (searchPrompt(searchData.keyword))),
                    circle: "",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 4,
          class: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (show_dialog(true,null))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "plus",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.create')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (promptsDelete(-1))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "trash-can",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delete')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_divider),
            _createVNode(_unref(ElTable), {
              ref_key: "promptTable",
              ref: promptTable,
              data: tableData.value,
              stripe: "",
              "show-overflow-tooltip": "",
              height: _unref(tableHeight),
              "default-sort": { prop: 'create_datetime', order: 'descending' },
              onSelectionChange: handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "expand" }, {
                  default: _withCtx((props) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                (props.row.modify_datetime)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('prompt.modify_datetime')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                                      _createTextVNode(_toDisplayString(_unref(formatDate)(props.row.modify_datetime, 'yyyy-MM-dd HH:mm')), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (props.row.system_role_content)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('prompt.system_role_content')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.system_role_content), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 12,
                            style: {"align-content":"flex-start"}
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                (props.row.command)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('prompt.command')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.command), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true),
                                (props.row.output_parser)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                      _createVNode(_component_el_text, { class: "title_text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('prompt.output_parser')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                                      _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(props.row.output_parser), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          (props.row.prompt_items && props.row.prompt_items.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_el_divider, { "content-position": "left" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('prompt.prompt_items')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_unref(ElTable), {
                                  data: props.row.prompt_items,
                                  "default-sort": { prop: 'item_sort', order: 'ascending' },
                                  class: "sub-table"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.item_sort'),
                                      prop: "item_sort",
                                      width: "60px",
                                      align: "center"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.item_name'),
                                      prop: "item_name",
                                      width: "130px",
                                      class: "multi-line-text"
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createElementVNode("span", {
                                          innerHTML: formatText(scope.row.item_name)
                                        }, null, 8, _hoisted_11)
                                      ]),
                                      _: 1
                                    }, 8, ["label"]),
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.item_intro'),
                                      prop: "item_intro",
                                      width: "300px",
                                      class: "multi-line-text"
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createElementVNode("span", {
                                          innerHTML: formatText(scope.row.item_intro)
                                        }, null, 8, _hoisted_12)
                                      ]),
                                      _: 1
                                    }, 8, ["label"]),
                                    _createVNode(_component_el_table_column, {
                                      label: _ctx.$t('prompt.item_default'),
                                      prop: "item_default",
                                      class: "multi-line-text"
                                    }, {
                                      default: _withCtx((scope) => [
                                        _createElementVNode("span", {
                                          innerHTML: formatText(scope.row.item_default)
                                        }, null, 8, _hoisted_13)
                                      ]),
                                      _: 1
                                    }, 8, ["label"])
                                  ]),
                                  _: 2
                                }, 1032, ["data"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  width: "30"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", null, [
                      (row.publish)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14))
                        : (_openBlock(), _createElementBlock("span", _hoisted_15))
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: _ctx.$t('prompt.name'),
                  width: "260",
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "rb_id",
                  label: _ctx.$t('prompt.rb_id'),
                  width: "60",
                  align: "center"
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "description",
                  label: _ctx.$t('prompt.description'),
                  class: "multi-line-text"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      innerHTML: formatText(scope.row.description)
                    }, null, 8, _hoisted_16)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "create_datetime",
                  label: _ctx.$t('user.create-date'),
                  width: "150",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(row.create_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "120",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      plain: "",
                      onClick: ($event: any) => (show_dialog(false,row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (promptsDelete(row.prompt_id)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data", "height"]),
            _createVNode(NewPrompt, {
              modelValue: showDialog.value,
              "onUpdate:isShow": _cache[8] || (_cache[8] = ($event: any) => (showDialog.value = $event)),
              onRefreshList: _cache[9] || (_cache[9] = ($event: any) => {handleCurrentChange(1);})
            }, null, 8, ["modelValue"]),
            _createVNode(NewPrompt, {
              modelValue: showEditDialog.value,
              prompt_info: cur_prompt.value,
              "onUpdate:isShow": _cache[10] || (_cache[10] = ($event: any) => (showEditDialog.value = $event)),
              onRefreshList: _cache[11] || (_cache[11] = ($event: any) => {handleCurrentChange(1);})
            }, null, 8, ["modelValue", "prompt_info"]),
            _createVNode(_component_el_pagination, {
              "current-page": currentPage.value,
              "onUpdate:currentPage": _cache[12] || (_cache[12] = ($event: any) => ((currentPage).value = $event)),
              "page-size": pageSize.value,
              "onUpdate:pageSize": _cache[13] || (_cache[13] = ($event: any) => ((pageSize).value = $event)),
              "page-sizes": [20, 50, 100, 200],
              layout: "total, sizes, prev, pager, next, jumper",
              total: totalItems.value,
              onSizeChange: _cache[14] || (_cache[14] = ($event: any) => (handleSizeChange(pageSize.value))),
              onCurrentChange: _cache[15] || (_cache[15] = ($event: any) => (handleCurrentChange(currentPage.value)))
            }, null, 8, ["current-page", "page-size", "total"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})