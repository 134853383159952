// 文件位置：service/tools.ts
import axios from "@/setups/axios-setup";
import config from "@/services/config";
import CryptoJS from "crypto-js";
import {onMounted, onUnmounted, ref, Ref} from 'vue';
import {format} from 'date-fns';
import {ElLoading, ElMessageBox, ElNotification} from "element-plus";
import {KeyPrefix, keys, KeySuffix, SysConfigResponse, SystemOption, SystemOptionType, TreeNode} from "@/types/system";

const cache: Map<string, { sunrise: Date, sunset: Date }> = new Map();

// 文件大小格式化函数
export function formatFileSize(size: number): string {
  if (size === 0) return '';
  const k = 1024;
  const sizes = ['B', 'K', 'M', 'G', 'T'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return (size / Math.pow(k, i)).toFixed(1) + sizes[i];
}

/**
 * 保存系统选项配置
 * @param {SystemOptionType} options - 需要保存的系统配置对象
 * @param {File | null} avatar_pic - 可选的用户头像文件
 * @returns {Promise<string>} - 返回保存成功的消息
 */
export const saveSystemOptions = async (options: SystemOptionType, avatar_pic: File | null): Promise<string | undefined> => {
    try {
        const data = new FormData();
        data.append('options', JSON.stringify(options)); // 如果 options 是一个对象
        if (avatar_pic) {
            data.append('file', avatar_pic);
        }

        const response = await axios.post(`${config.apiSysUrl}/save_configs/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });


        return response.data.message;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'InvalidInput':
                showNotification('warning', '保存系统参数', "无效的输入");
                break;
            case 'OptionNotFound':
                showNotification('warning', '保存系统参数', "系统参数未找到");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
};

/**
 * 加载系统选项配置
 * @returns {Promise<void>} - 加载并更新系统选项
 */
export const loadSystemOptions = async (): Promise<void> => {
    try {
        const response = await axios.get<SysConfigResponse>(`${config.apiSysUrl}/get_sys_config/`);

        for (const item of response.data.message) {
            SystemOption[item.key] = item.value;
        }

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NoSystemOptions':
                showNotification('warning', '保存系统参数', "系统参数未找到");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
};

/**
 * 显示加载指示器
 * @param {Function} t - 国际化函数
 * @param {HTMLElement} target - 需要显示加载指示器的目标元素
 * @returns {ReturnType<typeof ElLoading.service>} 加载指示器实例
 */
export const showLoading = (t: Function, target: HTMLElement): ReturnType<typeof ElLoading.service> => {
    return ElLoading.service({
        target: target,
        lock: true,
        text: t('message.loading')
    });
};

/**
 * 检查用户是否已登录
 * @returns {Promise<boolean>} - 返回一个 Promise，表示用户是否已登录
 */
export const checkLogin = async (): Promise<boolean> => {
    try {
        const {useUserStore} = await import('@/stores/userStore');
        const userStore = useUserStore();

        // 如果已经登录且用户信息已经加载，则不再重复请求
        if (userStore.loginUser) {
            return true;
        }
        const response = await axios.post(`${config.apiUserUrl}/check-login`);
        if (response.data.logged_in) {
            const user = await userStore.loadUser(true);
            if (user !== null) {
                userStore.loginUser = user;
            } else {
                userStore.loginUser = null;
            }
        }
        return response.data.logged_in;
    } catch (error) {
        return false;
    }
}

/**
 * 加密数据
 * @param {object | string} data - 需要加密的对象数据或字符串
 * @returns {string} - 加密后的字符串
 */
export const encryptData = (data: object | string): string => {
    try {
        if (!data) {
            return "";
        }
        // 如果是字符串并且已经加密，直接返回
        if (typeof data === 'string' && data.startsWith('ENC:')) {
            return data;
        }

        // 判断 data 是否为字符串
        const dataToEncrypt = typeof data === 'string' ? data : JSON.stringify(data);

        // 加密数据并添加标志
        const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, CryptoJS.enc.Utf8.parse(config.encryptionKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

        return `ENC:${encryptedData}`;
    } catch (error) {
        console.error('加密数据时发生错误:', error);
        return '';
    }
}

/**
 * 解密数据
 * @param {string} ciphertext - 加密后的字符串
 * @returns {any} - 解密后的对象数据或字符串
 */
export const decryptData = (ciphertext: string): any => {
    let decryptedData: string | null = null;

    if (!ciphertext || !ciphertext.startsWith('ENC:')) {
        return null;
    }

    try {
        const encryptedData = ciphertext.slice(4);
        const bytes = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(config.encryptionKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        return JSON.parse(decryptedData);  // 如果是 JSON 格式，解析成功
    } catch (error) {
        console.error('解密数据时发生错误:', error);
        return decryptedData;  // 如果不是 JSON 格式，直接返回解密后的字符串
    }
}


/**
 * 获取表格高度的自定义 Hook
 * @param {number} [offset=190] - 表格高度的偏移量，默认值为 190
 * @returns {Ref<number>} - 返回一个引用类型的表格高度
 */
export const autoHeight = (offset: number = 190): Ref<number> => {
    const height: Ref<number> = ref(0);
    let resizeObserver: ResizeObserver | null = null;

    const handleResize = () => {
        const mainContainer = document.querySelector('.el-main') as HTMLElement;

        if (mainContainer) {
            // 使用 requestAnimationFrame 来避免观察者循环问题
            requestAnimationFrame(() => {
                height.value = mainContainer.clientHeight - offset;
            });
        }
    };

    onMounted(() => {
        const mainContainer = document.querySelector('.el-main') as HTMLElement;

        if (mainContainer) {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(mainContainer);
        }

        // 初始设置高度
        handleResize();
    });

    onUnmounted(() => {
        if (resizeObserver) {
            resizeObserver.disconnect();
        }
    });

    return height;
};


/**
 * 格式化日期
 * @param {Date | null | undefined} date - 需要格式化的日期对象
 * @param {string} [dateFormat='yyyy-MM-dd HH:mm'] - 日期格式字符串，默认值为 'yyyy-MM-dd HH:mm'
 * @returns {string} - 返回格式化后的日期字符串，如果日期为 null 或 undefined，则返回空字符串
 */
export const formatDate = (date: Date | null | undefined, dateFormat: string = 'yyyy-MM-dd HH:mm'): string => {
    try {
        if (date) {
            return format(date, dateFormat);
        } else {
            return '';
        }
    } catch (error) {
        console.error('格式化日期时发生错误:', error);
        return '';
    }
}

/**
 * 显示通知
 * @param {'success' | 'warning' | 'info' | 'error'} type - 通知类型
 * @param {string} title - 通知标题
 * @param {string} message - 通知内容
 */
export const showNotification = (type: 'success' | 'warning' | 'info' | 'error', title: string, message: string): void => {
    ElNotification({
        title,
        message,
        type,
    });
}

/**
 * 显示消息框
 * @param {Function} t - 国际化函数，用于消息框标题的翻译
 * @param {string} title - 消息框标题
 * @param {string} message - 消息内容
 * @param {boolean} [isAlert=true] - 是否为警告框，默认为 true
 */
export const showMessageBox = async (t: Function, title: string, message: string, isAlert: boolean = true): Promise<void> => {
    try {
        if (isAlert) {
            await ElMessageBox.alert(message, title, {
                confirmButtonText: t('message.close'),
                type: 'warning',
                center: true,
            });
        } else {
            await ElMessageBox.confirm(message, title, {
                confirmButtonText: t('message.confirm'),
                cancelButtonText: t('message.cancel'),
                type: 'warning',
                center: true,
            });
        }
    } catch (error) {
        if (error !== 'cancel') {
            console.error('显示消息框时发生错误:', error);
        }
        throw error;
    }
}

/**
 * 获取用户位置
 * @returns {Promise<{ latitude: number; longitude: number }>} - 返回包含纬度和经度的 Promise
 */
export const getUserLocation = async (): Promise<{ latitude: number; longitude: number }> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const {latitude, longitude} = position.coords;
                    resolve({latitude, longitude});
                },
                error => {
                    reject(error);
                }
            );
        } else {
            const error = new Error('此浏览器不支持地理定位。');
            reject(error);
        }
    });
}

/**
 * 获取日出日落时间
 * @param {number} latitude - 纬度
 * @param {number} longitude - 经度
 * @returns {Promise<{ sunrise: Date; sunset: Date }>} - 返回包含日出和日落时间的 Promise
 */
export const getSunriseSunset = async (latitude: number, longitude: number): Promise<{
    sunrise: Date;
    sunset: Date
}> => {
    const cacheKey = `${latitude}_${longitude}`;

    if (cache.has(cacheKey)) {
        return cache.get(cacheKey)!;
    }

    try {
        const response = await fetch(`https://api.sunrise-sunset.org/json?lat=${latitude}&lng=${longitude}&formatted=0`);
        if (!response.ok) {
            return {
                sunrise: new Date(new Date().setHours(7, 0, 0, 0)),  // 早上7点
                sunset: new Date(new Date().setHours(19, 0, 0, 0))   // 晚上7点
            };
        }
        const data = await response.json();
        const result = {
            sunrise: new Date(data.results.sunrise),
            sunset: new Date(data.results.sunset)
        };
        cache.set(cacheKey, result);
        return result;
    } catch (error) {
        return {
            sunrise: new Date(new Date().setHours(7, 0, 0, 0)),  // 早上7点
            sunset: new Date(new Date().setHours(19, 0, 0, 0))   // 晚上7点
        };
    }
}

/**
 * 保存模型参数
 * @param {Function} t - 国际化函数，用于翻译消息内容
 * @param {KeyPrefix} keyPrefix - 键的前缀
 * @param {KeySuffix[]} keysToSave - 需要保存的键的后缀数组
 * @returns {Promise<void>} - 保存操作的 Promise
 */
export const saveOption = async (t: Function, keyPrefix: KeyPrefix, keysToSave: KeySuffix[]): Promise<void> => {
    try {
        keysToSave.forEach(key => {
            const compositeKey = `${keyPrefix}_${key}` as keyof typeof keys;
            SystemOption[compositeKey] = encryptData(keys[compositeKey].value);
        });

        await saveSystemOptions(SystemOption, null);
        showNotification("success", t(`chat.${keyPrefix}`), `${t(`chat.${keyPrefix}`)} ${t("message.save-options-success")}`);
    } catch (error) {
        console.log('保存模型参数时发生错误:', error);
        throw error
    }
};

/**
 * 文本格式化函数
 * @param {string} text - 需要格式化的文本
 * @returns {string} - 返回格式化后的文本，换行符会替换为 <br> 标签
 */
export const formatText = (text: string): string => {
    if (!text) return '';
    // 将换行符替换为 <br> 标签
    return text.replace(/\n/g, '<br>');
}

export const get_user_label = async (user_id: number) => {
    try {
        const response = await axios.get(`${config.apiSysUrl}/user_label/${user_id}`);
        return response.data;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'InvalidEntityType':
                showNotification('warning', '获取标签关系', "无效关系类型");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
}

export const get_node_label = async (node_id: number) => {
    try {
        const response = await axios.get(`${config.apiSysUrl}/treenode_label/${node_id}`);
        return response.data;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'InvalidEntityType':
                showNotification('warning', '获取标签关系', "无效关系类型");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '保存系统参数', error.response.data.detail);
                break;
        }
    }
}

export const get_tree = async (tree_name: string) => {
    try {
        const response = await axios.get(`${config.apiSysUrl}/get_tree/${tree_name}`);
        return response.data;
    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'TreeNameNotEmpty':
                showNotification('warning', '知识库', "知识库名不能为空");
                break;
            case 'NoTreeNodeData':
                showNotification('warning', '知识库', "库内无任何目录");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取知识库结构', error.response.data.detail);
                break;
        }
    }
}


export const save_tree_node = async (node: TreeNode, del_child_label: boolean, add_child_label: boolean) => {
    try {

        const temp_node = {
            node_id: node.id,
            tree_name: node.tree_name,
            node_name: node.label,
            parent_id: node.parent_id,
            label_names: [],
            label_ids: node.label_ids,
            delete: false
        }
        // 使用FormData构建请求数据
        const formData = new FormData();
        formData.append('node_data', JSON.stringify(temp_node)); // 添加用户数据
        formData.append('del_child_label', String(del_child_label)); // 添加布尔值，转换为字符串
        formData.append('add_child_label', String(add_child_label)); // 添加布尔值，转换为字符串
        const response = await axios.post(`${config.apiSysUrl}/save_treenode/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NodeDataNotEmpty':
                showNotification('warning', '知识库', "节点名不能为空");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取知识库结构', error.response.data.detail);
                break;
        }
    }
}

export const del_tree_node = async (tree_name: string, node_id: number, del: boolean = false) => {
    try {
        const del_node = {
            tree_name: tree_name,
            node_id: node_id,
            delete: del,
        };

        const response = await axios.delete(`${config.apiSysUrl}/del_treenode`, {data: del_node});
        return response.data.message;

    } catch (error: any) {
        let detail = "null"
        if (error.response) {
            detail = error.response.data.detail;
        }
        switch (detail) {
            case 'NoTreeNode':
                showNotification('warning', '知识库', "树节点不存在");
                break;
            case 'null':
                break;
            default:
                showNotification('error', '获取知识库结构', error.response.data.detail);
                break;
        }
    }

}


