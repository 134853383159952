import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = { class: "pic-text-display" }
const _hoisted_3 = { class: "pic-text-display" }
const _hoisted_4 = { class: "pic-text-display" }

import {onMounted, reactive, ref} from 'vue';
import {ElTable} from 'element-plus';
import {User} from '@/types/User';
import {autoHeight, formatDate, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {useUserStore} from "@/stores/userStore";
import NewUser from "@/components/NewUser.vue";
import {useI18n} from "vue-i18n";

// 使用国际化功能

export default /*@__PURE__*/_defineComponent({
  __name: 'user_lib_m',
  setup(__props) {

const {t} = useI18n();


// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(20);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<User[]>([]);  // 表格数据
const vips = ref<string>("-1");  // 会员状态

const tableHeight = autoHeight(190);  // 表格高度自适应
const userStore = useUserStore();  // 使用用户存储
const multipleSelection = ref<User[]>([]);  // 多选用户
const userTableRef = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const showDialog = ref(false);  // 控制新增用户对话框显示
const showEditDialog = ref(false);  // 控制编辑用户对话框显示
const cur_user = ref<User>();  // 当前操作的用户
const searchData = reactive({
  keyword: ''  // 搜索关键字
});


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userStore.getUsers(searchData.keyword, Number(vips.value), currentPage.value, val);
    pageSize.value = val;
    tableData.value = userStore.users;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userStore.getUsers(searchData.keyword,  Number(vips.value), val, pageSize.value);
    currentPage.value = val;
    tableData.value = userStore.users;
  } finally {
    loading.close();
  }
};

/**
 * 搜索用户
 * @param {string} searchData - 搜索关键字
 */
const searchUser = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await userStore.getUsers(searchData,  Number(vips.value), 1, pageSize.value);
    tableData.value = userStore.users;
  } finally {
    loading.close();
  }
};

/**
 * 处理表格行选择变化
 * @param {User[]} val - 选中的用户列表
 */
const handleSelectionChange = (val: User[]) => {
  multipleSelection.value = val;
};

/**
 * 删除用户
 * @param {number} cur_row - 当前操作的行号
 */
const usersDelete = async (cur_row: number) => {
  const userIds: number[] = [];
  try {
    const cur_user = await userStore.loadUser();
    if (!cur_user) {
      await showMessageBox(t, t('message.del-title'), t('message.del-Unauthorized'));
      return;
    }

    // 检查是否有选中的用户
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.del-last-one'));
      return;
    }

    // 检查当前用户是否在操作列表中或尝试操作自己
    if (cur_user.user_id === cur_row || multipleSelection.value.some(user => user.user_id == cur_user.user_id)) {
      await showMessageBox(t, t('message.del-title'), t('message.del-self'));
      return;
    }

    // 如果传入的行号有效且不是自己，添加到 userIds
    if (cur_row !== -1) {
      userIds.push(cur_row);
    } else {
      userIds.push(...multipleSelection.value.map(user => user.user_id!));
    }

    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    // 执行删除操作
    const target = document.querySelector('.el-table') as HTMLElement;
    const loading = showLoading(t, target);
    await userStore.del_Users(userIds);

    tableData.value = tableData.value.filter(item =>
        !userIds.includes(item.user_id!)
    );
    totalItems.value = totalItems.value - userIds.length;
    const count = userIds.length;
    showNotification("success", t('message.del-success-title'), t('user.del-user-success', {count}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        await showMessageBox(t, t('message.del-title'), t('message.del-Unauthorized'));
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
};

/**
 * 显示对话框
 * @param {boolean} new_user - 是否是新增用户
 * @param {User | null} user_data - 当前用户数据
 */
const show_dialog = async (new_user: boolean, user_data: User | null) => {
  if (new_user) {
    showDialog.value = true;
    showEditDialog.value = false;
  } else {
    showDialog.value = false;
    showEditDialog.value = true;
    if (user_data) {
      cur_user.value = user_data;
    }
  }
};

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("user_page_size");
  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 20,
          class: "left"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_radio_group, {
                modelValue: vips.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vips).value = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (searchUser(searchData.keyword))),
                style: {"white-space":"nowrap"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('message.all'),
                    value: "-1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('user.no-vip'),
                    value: "0",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('user.expired-vip'),
                    value: "1",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('user.new-vip'),
                    value: "2",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('user.pro-vip'),
                    value: "3",
                    border: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_radio_button, {
                    label: _ctx.$t('user.manager-role'),
                    value: "4",
                    border: ""
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                style: {"height":"25px"}
              }),
              _createVNode(_component_el_form, {
                ref: "searchForm",
                model: searchData,
                inline: true,
                class: "search-form",
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "searchKey",
                    class: "keyword-input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: searchData.keyword,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchData.keyword) = $event)),
                        placeholder: _ctx.$t('user.SearchKey'),
                        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (searchUser(searchData.keyword)), ["enter"])),
                        style: {"width":"150px"},
                        clearable: ""
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (searchUser(searchData.keyword))),
                    circle: "",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          span: 4,
          class: "right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button_group, { style: {"white-space":"nowrap"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (show_dialog(true,null))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "plus",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.create')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "danger",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (usersDelete(-1))),
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "trash-can",
                      class: "icon-text-button"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delete')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_divider),
            _createVNode(_unref(ElTable), {
              ref_key: "userTableRef",
              ref: userTableRef,
              data: tableData.value,
              stripe: "",
              "show-overflow-tooltip": "",
              height: _unref(tableHeight),
              "default-sort": { prop: 'create_datetime', order: 'descending' },
              onSelectionChange: handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  prop: "name",
                  label: _ctx.$t('user.nickname'),
                  width: "220"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", _hoisted_2, [
                      _createVNode(_component_el_avatar, {
                        src: row.avatar_url,
                        size: "small",
                        class: "pic-text-display"
                      }, null, 8, ["src"]),
                      _createElementVNode("span", null, _toDisplayString(row.name), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "login_name",
                  label: _ctx.$t('user.login-name'),
                  width: "180"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(row.login_name), 1),
                      (row.role === 1)
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            icon: "user-tie",
                            style: {"color":"#529b2e"}
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "membership_end_date",
                  label: _ctx.$t('user.vip-date'),
                  width: "150",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(row.membership_end_date, 'yyyy-MM-dd')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "mobile",
                  label: _ctx.$t('user.mobile'),
                  width: "120"
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "create_datetime",
                  label: _ctx.$t('user.create-date'),
                  width: "150",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(row.create_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "email",
                  label: _ctx.$t('user.email'),
                  width: "auto"
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "native_language",
                  label: _ctx.$t('user.native_language'),
                  width: "auto"
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "120",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      plain: "",
                      onClick: ($event: any) => (show_dialog(false,row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (usersDelete(row.user_id)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data", "height"]),
            _createVNode(NewUser, {
              modelValue: showDialog.value,
              "onUpdate:isShow": _cache[8] || (_cache[8] = ($event: any) => (showDialog.value = $event)),
              onRefreshList: _cache[9] || (_cache[9] = ($event: any) => {handleCurrentChange(1);})
            }, null, 8, ["modelValue"]),
            _createVNode(NewUser, {
              modelValue: showEditDialog.value,
              userinfo: cur_user.value,
              "onUpdate:isShow": _cache[10] || (_cache[10] = ($event: any) => (showEditDialog.value = $event)),
              onRefreshList: _cache[11] || (_cache[11] = ($event: any) => {handleCurrentChange(1);})
            }, null, 8, ["modelValue", "userinfo"]),
            _createVNode(_component_el_pagination, {
              "current-page": currentPage.value,
              "onUpdate:currentPage": _cache[12] || (_cache[12] = ($event: any) => ((currentPage).value = $event)),
              "page-size": pageSize.value,
              "onUpdate:pageSize": _cache[13] || (_cache[13] = ($event: any) => ((pageSize).value = $event)),
              "page-sizes": [20, 50, 100, 200],
              layout: "total, sizes, prev, pager, next,jumper",
              total: totalItems.value,
              onSizeChange: _cache[14] || (_cache[14] = ($event: any) => (handleSizeChange(pageSize.value))),
              onCurrentChange: _cache[15] || (_cache[15] = ($event: any) => (handleCurrentChange(currentPage.value)))
            }, null, 8, ["current-page", "page-size", "total"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})