import {ref} from "vue";

export type SystemOptionType = {
    [key: string]: string;
};

// 定义一个全局的字典对象类型
export const SystemOption: SystemOptionType = {
    "ai_dev": "",
    "ai_desc": "",
    "ai_keyword": "",
    "ai_name": "",
    "ai_avatar": "",
    "logic_llm": "",
    "main_llm": "",
    "small_llm": "",
    "check_ak": "",
    "check_sk": "",
    "check_switch": "",
    // 月之暗面
    "moonshot_ak": "",
    "moonshot_url": "",
    // ChatGPT
    "openai_ak": "",
    "openai_url": "",
    // 百度千帆
    "baidu_ak": "",
    "baidu_sk": "",
    // 火山引擎
    "volc_ak": "",
    "volc_sk": "",
    // 百川大模型
    "baichuan_ak": "",
    "baichuan_url": ""
};

export interface SysConfigResponse {
    success: string;
    message: Array<{ key: string; value: string }>;
}

export type KeyPrefix = 'moonshot' | 'baidu' | 'baichuan' | 'volc' | 'openai' | 'check';
export type KeySuffix = 'ak' | 'url' | 'sk'; // 所有的 API 密钥和 URL 使用 ref 进行响应式存储
export const keys = {
    moonshot_ak: ref(""),
    moonshot_url: ref(""),
    baidu_ak: ref(""),
    baidu_sk: ref(""),
    baichuan_ak: ref(""),
    baichuan_url: ref(""),
    volc_ak: ref(""),
    volc_sk: ref(""),
    openai_ak: ref(""),
    openai_url: ref(""),
    check_ak: ref(""),
    check_sk: ref(""),
};

export interface TreeNode {
    id: number;
    label: string;
    tree_name: string;
    children?: TreeNode[];
    parent_id?: number | null;
    showButton?: boolean;
    label_names?: string[];
    label_ids?: number[];
    file_count?: number;
    isEditing?: boolean;
    isNew?: boolean;
}

export interface LabelUserRelation {
    label_id: number;
    user_id: number;
    label_name: string;
}

export interface LabelTreeRelation {
    label_id: number;
    node_id: number;
    label_name: string;
}