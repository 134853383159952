// 文件位置：services/validators.ts

// 验证密码规则
export function validatePassword(t: Function, rule: any, value: any, callback: any, must: boolean) {

    if (value.length == 0 && !must) {
        callback()
        return
    }

    if (value.length < 3) {
        callback(new Error(t('user.password-length-check')));
    } else if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
        callback(new Error(t('user.password-complex-check')));
    } else {
        callback();
    }
}

// 验证确认密码规则
export function validateConfirmPassword(t: Function, rule: any, value: any, callback: any, password: string) {
    if (value !== password) {
        callback(new Error(t('user.password-match-check')));
    } else {
        callback();
    }
}

// 验证手机号规则
export function validateMobile(t: Function, rule: any, value: any, callback: any) {
    if (value.length == 0) {
        callback()
        return
    }
    // 添加手机号验证
    const phonePatterns = [
        /^(\+?86)?1[3-9]\d{9}$/,                  // 中国
        /^(\+?81)?(0[789]0|[89]0)\d{8}$/,         // 日本
        /^(\+?1)?\d{10}$/,                        // 美国
        /^(\+?44)?7\d{9}$/,                       // 英国
        /^(\+?33|0)[67]\d{8}$/,                   // 法国
        /^(\+?34)?6\d{8}$/,                       // 西班牙
        /^(\+?82)?(0[17]|[1][01])\d{8}$/,         // 韩国
        /^(\+?886)?9\d{8}$/                       // 台湾
    ];

    const isPhoneValid = phonePatterns.some(pattern => pattern.test(value));

    if (!isPhoneValid) {
        callback(new Error(t('user.mobile-check')))
        return
    } else {
        callback()
    }


}
