import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "col-left" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-top":"10px"}
}
const _hoisted_3 = { class: "col-right" }
const _hoisted_4 = { class: "divider_text" }
const _hoisted_5 = { class: "div_right" }

import {computed, onMounted, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {Prompt, Promptitems} from "@/types/Prompt" // 导入 User 类型定义
import {Robot} from "@/types/Robot"
import {usePromptStore} from "@/stores/promptStore" // 导入用户存储
import {showNotification} from "@/services/tools"
import {useRobotStore} from "@/stores/robotStore";

// 使用用户存储和国际化

export default /*@__PURE__*/_defineComponent({
  __name: 'NewPrompt',
  props: { // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  prompt_info: {
    type: Object as () => Prompt,
    default: null
  }
},
  emits: ["refreshList", "update:isShow"],
  setup(__props, { emit: __emit }) {

const promptStore = usePromptStore() // 使用用户存储
const robotStore = useRobotStore()
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newPromptMessage = ref<string>('') // 新模板信息提示
const promptForm = ref<FormInstance>() // 模板表单实例
const promptDialog = ref(false) // 创建对话框显示状态


// 定义响应式数据
const options = ref<Robot[]>([])
const robot = ref<Robot>({} as Robot)

const prompt = ref<Prompt>({} as Prompt) // 创建用户对象
const PromptData = ref({ // 模板数据对象
  name: '',
  description: '',
  system_role_content: '',
  robot_id: -1,
  command: '',
  output_parser: '',
  publish: false,
  prompt_items: [
    {item_id: -1, prompt_id: -1, item_sort: 1, item_name: '', item_intro: '', item_default: ''} as Promptitems  // 初始时包含一个空项目
  ],
  robot: {
    robot_id: -1,
    name: '',
    description: '',
    system_role_content: '',
    command: '',
    output_parser: '',
    publish: false
  } as Robot
})
// 初始化模板数据
const defaultPromptData = {
  name: '',
  description: '',
  system_role_content: '',
  robot_id: -1,
  command: '',
  output_parser: '',
  publish: false,
  prompt_items: [
    {item_id: -1, prompt_id: -1, item_sort: 1, item_name: '', item_intro: '', item_default: ''} as Promptitems  // 初始时包含一个空项目
  ],
  robot: {
    robot_id: -1,
    name: '',
    description: '',
    system_role_content: '',
    command: '',
    output_parser: '',
    publish: false
  } as Robot
}
// 定义一个默认的Robot对象
const defaultRobot: Robot = {
  rb_id: -1,
  name: '',
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  create_datetime: null,
  modify_datetime: null
};

// 定义组件的 props 和 emits
const props = __props
const emit = __emit // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  promptDialog.value = val // 设置对话框显示状态
  newPromptMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.userinfo 的变化并初始化相关状态
watch(() => props.prompt_info, (newVal) => {
  if (newVal) {
    prompt.value = newVal;
    ini_form(prompt.value)
  } else {
    prompt.value = {} as Prompt;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const promptRules = computed(() => ({
  name: [{required: true, max: 50, message: t('prompt.name-check'), trigger: 'blur'}], // 昵称验证规则
  description: [{required: true, max: 100, message: t('prompt.description-check'), trigger: 'blur'}], // 登录名验证规则
}))


// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    promptDialog.value = false // 隐藏对话框
    newPromptMessage.value = ""
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件

  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

// 上传用户信息
const savePrompt = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;

  try {
    const valid = await formEl.validate(); // 验证表单

    if (valid) {
      setPromptValues(PromptData.value); // 设置 prompt 的值
      newPromptMessage.value = ""; // 清空提示信息
      await promptStore.createOrUpdatePrompt(prompt.value); // 保存模板
      PromptData.value = {...defaultPromptData} //清空PromptData数据
      newPromptMessage.value = ""
      refreshFatherList() // 刷新父组件列表
      await closeDialog() // 关闭对话框
      showNotification('success', t('message.success'), t('prompt.new-success-info')) // 显示成功通知
    }
  } catch (error: any) {
    let detail = "null"
    newPromptMessage.value = "";
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'PromptNameExisted':
        newPromptMessage.value = t('prompt.name-exist'); // 注册名已存在
        break;
      case 'MissingPromptID':
        newPromptMessage.value = "任务ID不能为空"; // 注册名已存在
        break;
      case 'PromptNotFound':
        newPromptMessage.value = "任务数据找不到"; // 未知错误
        break;
      case 'null':
        break;
      default:
        showNotification('error', '服务端错误', error.response.data.detail);
        break;
    }
  }

};

// 帮助函数来设置 prompt 的值
const setPromptValues = (data: typeof PromptData.value) => {
  prompt.value.name = data.name; // 设置模板名称
  prompt.value.description = data.description; // 设置模板描述
  prompt.value.system_role_content = data.system_role_content; // 设置模板系统角色内容
  prompt.value.rb_id = data.robot_id; // 设置机器人 ID
  prompt.value.command = data.command; // 设置模板命令
  prompt.value.output_parser = data.output_parser; // 设置模板输出解析器
  prompt.value.publish = data.publish; // 设置模板发布状态
  prompt.value.prompt_items = data.prompt_items; // 设置模板项目
};

const ini_form = async (prompt: Prompt) => {
  if (prompt) {
    PromptData.value.name = prompt.name || '' // 设置用户头像 URL
    PromptData.value.description = prompt.description || ''// 设置用户昵称
    PromptData.value.command = prompt.command || '' // 设置用户登录名
    PromptData.value.system_role_content = prompt.system_role_content || '' // 设置用户密码
    PromptData.value.robot_id = prompt.rb_id || -1
    PromptData.value.output_parser = prompt.output_parser || ''// 设置用户手机
    PromptData.value.publish = prompt.publish || false //设置管发布状态
    PromptData.value.prompt_items = prompt.prompt_items || [{
      item_id: -1,
      prompt_id: -1,
      item_sort: 1,
      item_name: '（无）',
      item_intro: '',
      item_default: ''
    }] // 设置项目
    if (prompt.rb_id && prompt.rb_id != -1) {
      await robotStore.getRobotById(prompt.rb_id || -1)
      robot.value = robotStore.robot
    } else {
      robot.value = defaultRobot
    }

  }
}

const addItem = () => {
  if (PromptData.value.prompt_items.length < 5) {
    newPromptMessage.value = ""
    PromptData.value.prompt_items.push({
      item_id: -1,
      prompt_id: -1,
      item_sort: PromptData.value.prompt_items.length + 1,
      item_name: '',
      item_intro: '',
      item_default: ''
    });
  } else {
    newPromptMessage.value = "最多只能添加5项";
  }
};

const removeItem = (index: number) => {
  newPromptMessage.value = ""
  PromptData.value.prompt_items.splice(index, 1);
};

// 获取选项数据的函数
const fetchOptions = async (robot_id: number) => {
  try {
    if (!robot_id) return
    if (robot_id == -1) {
      await robotStore.getRobotsSummary()
      options.value = [defaultRobot, ...robotStore.robotSummary]
      PromptData.value.robot_id = -1
      PromptData.value.system_role_content = ""
      return
    } else {
      for (const item of options.value) {
        if (item.rb_id == robot_id) {
          PromptData.value.robot = item as Robot
          PromptData.value.robot_id = robot_id
          PromptData.value.system_role_content = item.system_role_content
          break
        }
      }
    }

  } catch (error) {
    console.error('Error fetching options:', error);
  }
};

// 组件挂载时调用
onMounted(() => {
  fetchOptions(-1);
});


return (_ctx: any,_cache: any) => {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: promptDialog.value,
    onClose: closeDialog,
    title: _ctx.$t('prompt.new-title'),
    width: "1100px",
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (savePrompt(promptForm.value)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.move')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref_key: "promptForm",
        ref: promptForm,
        model: PromptData.value,
        rules: promptRules.value,
        "label-width": "100px",
        "label-position": 'right'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (newPromptMessage.value)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    type: "warning",
                    description: newPromptMessage.value,
                    closable: false,
                    "show-icon": "",
                    effect: 'light',
                    class: "reg-alert"
                  }, null, 8, ["description"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('prompt.name'),
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: PromptData.value.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((PromptData.value.name) = $event)),
                          placeholder: _ctx.$t('prompt.name-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "50",
                          style: {"width":"100%"}
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('prompt.description'),
                      prop: "description"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: PromptData.value.description,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((PromptData.value.description) = $event)),
                          placeholder: _ctx.$t('prompt.description-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "100"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('message.robot'),
                      prop: "system_role_content"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: robot.value,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((robot).value = $event)),
                          "value-key": "rb_id",
                          placeholder: _ctx.$t('robot.select'),
                          style: {"width":"100%"},
                          change: fetchOptions(Number(robot.value.id))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.rb_id,
                                label: item.name,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "placeholder", "change"]),
                        _createVNode(_component_el_text, null, {
                          default: _withCtx(() => [
                            (robot.value.system_role_content)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(robot.value.system_role_content), 1)
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('prompt.publish'),
                      prop: "publish"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: PromptData.value.publish,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((PromptData.value.publish) = $event)),
                          "inline-prompt": "",
                          "active-text": _ctx.$t('message.yes'),
                          "inactive-text": _ctx.$t('message.no')
                        }, null, 8, ["modelValue", "active-text", "inactive-text"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('prompt.command'),
                      prop: "command"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: PromptData.value.command,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((PromptData.value.command) = $event)),
                          placeholder: _ctx.$t('prompt.command-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "400"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('prompt.output_parser'),
                      prop: "output_parser"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: PromptData.value.output_parser,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((PromptData.value.output_parser) = $event)),
                          placeholder: _ctx.$t('prompt.output_parser-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "200"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_divider, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('prompt.prompt_items')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_button, {
              onClick: addItem,
              type: "primary",
              plain: "",
              round: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "plus",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('prompt.item_add')), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { style: {"width":"100%"} }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(PromptData.value.prompt_items, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          style: {"margin-top":"15px","width":"100%"}
                        }, [
                          _createVNode(_component_el_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, { span: 3 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_select, {
                                        modelValue: item.item_sort,
                                        "onUpdate:modelValue": ($event: any) => ((item.item_sort) = $event),
                                        placeholder: _ctx.$t('prompt.item_sort')
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_option, {
                                            label: "1",
                                            value: "1"
                                          }),
                                          _createVNode(_component_el_option, {
                                            label: "2",
                                            value: "2"
                                          }),
                                          _createVNode(_component_el_option, {
                                            label: "3",
                                            value: "3"
                                          }),
                                          _createVNode(_component_el_option, {
                                            label: "4",
                                            value: "4"
                                          }),
                                          _createVNode(_component_el_option, {
                                            label: "5",
                                            value: "5"
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_el_col, { span: 20 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_input, {
                                        modelValue: item.item_name,
                                        "onUpdate:modelValue": ($event: any) => ((item.item_name) = $event),
                                        placeholder: _ctx.$t('prompt.item_name'),
                                        clearable: "",
                                        "show-word-limit": "",
                                        maxlength: "50"
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_el_col, { span: 1 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_button, {
                                        type: "danger",
                                        onClick: ($event: any) => (removeItem(index)),
                                        plain: "",
                                        circle: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_el_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, { span: 12 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_input, {
                                        modelValue: item.item_intro,
                                        "onUpdate:modelValue": ($event: any) => ((item.item_intro) = $event),
                                        placeholder: _ctx.$t('prompt.item_intro'),
                                        clearable: "",
                                        type: "textarea",
                                        rows: 4,
                                        "show-word-limit": "",
                                        maxlength: "100"
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_el_col, { span: 12 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_input, {
                                        modelValue: item.item_default,
                                        "onUpdate:modelValue": ($event: any) => ((item.item_default) = $event),
                                        placeholder: _ctx.$t('prompt.item_default'),
                                        clearable: "",
                                        type: "textarea",
                                        rows: 4,
                                        "show-word-limit": "",
                                        maxlength: "100"
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}
}

})