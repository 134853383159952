import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "language-sel" }

import {onBeforeMount, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useLocaleStore} from '@/stores/localStore';

// 获取 i18n 实例

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthLayout',
  setup(__props) {


const {locale: i18nLocale} = useI18n();
const {t} = useI18n();

// 设置响应式的语言状态
const language = ref(t('language.zh'));
const languages = [t('language.zh'), t('language.en'), t('language.ja'), t('language.fr'), t('language.es')]

const localeStore = useLocaleStore();

const changeLanguage = () => {
  let temp: ('zh' | 'en' | 'fr' | 'ja' | 'es')

  switch (language.value) {
    case t('language.zh'):
      temp = "zh";
      break
    case t('language.en'):
      temp = "en";
      break
    case t('language.ja'):
      temp = "ja";
      break
    case t('language.fr'):
      temp = "fr"
      break
    case t('language.es'):
      temp = "es"
      break
    default:
      temp = "zh"
  }
  localeStore.setLocale(temp);
  i18nLocale.value = temp;
};

// 执行设置语言操作
onBeforeMount(() => {
  switch (localeStore.getLocale) {
    case "zh":
      language.value = t('language.zh');
      break
    case "en":
      language.value = t('language.en');
      break
    case "ja":
      language.value = t('language.ja');
      break
    case "fr":
      language.value = t('language.fr');
      break
    case "es":
      language.value = t('language.es');
      break
    default:
      language.value = t('language.zh');
  }
  changeLanguage();
});


return (_ctx: any,_cache: any) => {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_segmented = _resolveComponent("el-segmented")!
  const _component_el_footer = _resolveComponent("el-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_container, { class: "login-bg" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_aside, { class: "login-pic" }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createVNode(_component_el_footer, { class: "footer" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('message.footer')), 1),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_segmented, {
                modelValue: language.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((language).value = $event)),
                options: languages,
                onChange: changeLanguage
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 3
    })
  ]))
}
}

})