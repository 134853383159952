<script setup lang="ts">
import {useI18n} from "vue-i18n" // 导入国际化插件
import {autoHeight, formatDate, formatFileSize, showLoading, showMessageBox, showNotification} from "@/services/tools";
import {ElTable, FormInstance} from "element-plus";
import {computed, onMounted, reactive, ref} from 'vue';
import {useFileStore} from "@/stores/fileStore";
import TreeList from "@/components/TreeList.vue";
import {KnowledgeFile} from "@/types/KnowledgeFile";
import selectTreeList from "@/components/selectTreeList.vue";
import UploadKnowledgeFile from "@/components/UploadKnowledgeFile.vue";


// 使用国际化功能
const {t} = useI18n();

// 定义响应式变量
const treeListRef = ref<InstanceType<typeof TreeList>>(null);
const showDialog = ref<boolean>(false);
const showUploadDialog = ref<boolean>(false);
const fileModifyForm = ref<FormInstance>() // 模板表单实例
const cur_node_id = ref(-1);  // 当前选中的节点ID
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<KnowledgeFile[]>([]);  // 表格数据
const tableHeight = autoHeight(200);  // 表格高度自适应
const multipleSelection = ref<KnowledgeFile[]>([]);  // 多选用户
const fileStore = useFileStore();  // 使用用户存储
const fileTable = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const cur_File = ref<KnowledgeFile | null>(null);  // 当前选中的模型
const modifyFileInfo = ref<boolean>(false);
const errorMessage = ref<string>('') // 新模板信息提示
const fileIds = ref<number[]>([]);
const options = ref<any[]>([
  {value: "-1", label: "全部"},
  {value: "0", label: "进行中"},
  {value: "1", label: "已完成"}]);

const searchData = reactive({
  keyword: '',  // 搜索关键字
  all: true,
  text: null,
  graph: null,
  vectorization: null
});

// 表单验证规则
const fileRules = computed(() => ({
  name: [{required: true, max: 200, message: t('knowledge.name-check'), trigger: 'blur'}], // 名称验证规则
}))

const handleSelectionChange = (val: KnowledgeFile[]) => {
  multipleSelection.value = val;
};

const searchFiles = async (searchData: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    tableData.value = fileStore.knowledgeFileList
  } finally {
    loading.close();
  }
};

const saveFile = async (formEl: FormInstance | undefined) => {
  if (!formEl) return

  try {
    const valid = await formEl.validate() // 验证表单

    if (valid && cur_File.value) {
      if (cur_File.value.text_content == "") {
        cur_File.value.convert_text = false
        cur_File.value.convert_text_datetime = null
      } else {
        cur_File.value.convert_text = true
        cur_File.value.convert_text_datetime = new Date()
      }
      await fileStore.createOrUpdateFile(cur_File.value, null)
      //清空PromptData数据
      modifyFileInfo.value = false
      cur_File.value.name = ""
      cur_File.value.text_content = ""
      cur_File.value.version = 1
      errorMessage.value = ""
      showNotification('success', t('message.success'), t('knowledge.modify-success-info')) // 显示成功通知
      await handleCurrentChange(currentPage.value)

    }
  } catch (error: any) {
    modifyFileInfo.value = true
    errorMessage.value = ""
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        errorMessage.value = t('message.no-empty');
        break;
      case 'MissingFileID':
        errorMessage.value = t('knowledge.fileid-no-exist') // 显示用户名已存在提示
        break;
      case "NotFoundFile":
        errorMessage.value = t('knowledge.file-no-exist') // 显示用户名已存在提示
        break;
      case 'null':
        break;
      default:
        console.log(error.response.data)
        showNotification('error', '未知错误', t('user.unknown-error'));
        break;
    }
  }
}

/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, currentPage.value, val);
    pageSize.value = val;
    tableData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, val, pageSize.value);
    currentPage.value = val;
    tableData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const handleSelectedNodeChange = async (node: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    cur_node_id.value = node.data.id;
    searchData.all = false;
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    currentPage.value = 1;
    tableData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const refresh_list_tree = () => {
  handleCurrentChange(currentPage.value);
  treeListRef.value.refreshTree();
}

const show_upload_dialog = async () => {
  try {
    if (cur_node_id.value > 0) {
      showUploadDialog.value = true
    } else {
      await showMessageBox(t, t('message.upload'), t('knowledge.select-node'));
    }
  } catch (error) {
    console.log(error)
  }
}

const moveFiles = async () => {
  try {
    // 检查是否有选中的用户
    if (multipleSelection.value.length === 0) {
      await showMessageBox(t, t('knowledge.move-title'), t('message.del-last-one'));
      return;
    }
    fileIds.value.length = 0
    // 如果传入的行号有效添加到 fileIds
    fileIds.value.push(...multipleSelection.value.map(file => file.file_id!));
    showDialog.value = true
  } catch (error) {
    return;
  }
};


const deleteFiles = async (cur_row: number) => {
  const fileIds: number[] = [];

  try {
    // 检查是否有选中的用户
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.del-last-one'));
      return;
    }
  } catch (error) {
    return;
  }

  // 如果传入的行号有效添加到 fileIds
  if (cur_row !== -1) {
    fileIds.push(cur_row);
  } else {
    fileIds.push(...multipleSelection.value.map(file => file.file_id!));
  }

  // 执行删除操作
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    await fileStore.deleteFiles(fileIds);
    // 处理删除结果
    tableData.value = tableData.value.filter(item =>
        !fileIds.includes(item.file_id!)
    );
    totalItems.value = totalItems.value - fileIds.length
    showNotification("success", t('message.del-success-title'), t('knowledge.del-file-success', {count: fileIds.length}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'UserAlreadyHasTask':
        showNotification('error', t('message.del-title'), "等待前一个删除任务完成再进行删除");
        break;
      case 'InvalidInput':
        showNotification("warning", t('message.del-title'), t('message.del-last-one'))
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  } finally {
    loading.close();
  }
};

const show_dialog = async (file_data: KnowledgeFile | null) => {
  cur_File.value = JSON.parse(JSON.stringify(file_data));
  errorMessage.value = ""
  modifyFileInfo.value = true;
};

const cancelFile = async () => {
  errorMessage.value = ""
  modifyFileInfo.value = false;
  showDialog.value = false;
};

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("file_page_size");
  searchData.text = options.value[0].value;
  searchData.vectorization = options.value[0].value;
  searchData.graph = options.value[0].value;
  searchData.all = true;

  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});

</script>

<template>
  <el-row class="top-row">
    <div class="pic-text-display">
      <el-form ref="searchForm"
               :model="searchData"
               :inline="true"
               class="search-form"
               @submit.prevent
      >
        <el-form-item prop="searchAll" class="top-input">
          <span style="margin-right: 6px">{{ t("knowledge.all") }}</span>
          <el-switch
              v-model="searchData.all"
              inline-prompt
              :active-text="$t('message.yes')"
              :inactive-text="$t('message.no')"
          />
        </el-form-item>
        <el-divider direction="vertical" class="set-color"/>
        <el-form-item prop="search_text" class="top-input">
          <span style="margin-right: 8px">{{ $t('knowledge.text-date') }}</span>
          <el-select
              v-model="searchData.text"
              style="width: 90px"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-divider direction="vertical" class="set-color"/>
        <el-form-item prop="search_graph" class="top-input">
          <span style="margin-right: 8px">{{ $t('knowledge.graph-date') }}</span>
          <el-select
              v-model="searchData.graph"
              style="width: 90px"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-divider direction="vertical" class="set-color"/>
        <el-form-item prop="search_vectorization" class="top-input">
          <span style="margin-right: 8px">{{ $t('knowledge.vectorization-date') }}</span>
          <el-select
              v-model="searchData.vectorization"
              style="width: 90px"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-divider direction="vertical" class="set-color"/>
        <el-form-item prop="searchKey" class="top-input">
          <el-input v-model="searchData.keyword" :placeholder="$t('user.SearchKey')"
                    @keyup.enter="searchFiles(searchData)"
                    style="width: 260px;"
                    clearable></el-input>
        </el-form-item>
        <el-button type="primary" @click="searchFiles(searchData)" circle plain>
          <template #default>
            <font-awesome-icon icon="magnifying-glass"/>
          </template>
        </el-button>
      </el-form>
    </div>
    <el-button-group>
      <el-button type="primary" @click="show_upload_dialog" round>
        <font-awesome-icon icon="upload" class="icon-text-button"/>
        {{ $t('message.upload') }}
      </el-button>
      <el-button type="primary" @click="moveFiles" round>
        <font-awesome-icon icon="arrow-right-arrow-left" class="icon-text-button"/>
        {{ $t('message.move') }}
      </el-button>
      <el-button type="danger" @click="deleteFiles(-1)" round>
        <font-awesome-icon icon="trash-can" class="icon-text-button"/>
        {{ $t('message.delete') }}
      </el-button>
    </el-button-group>
    <el-divider/>
  </el-row>
  <el-row :gutter="20" style="height: auto">
    <el-col :span="8">
      <TreeList ref="treeListRef" treeName="file" @selected-node-changed="handleSelectedNodeChange"></TreeList>
    </el-col>
    <el-col :span="16">
      <el-table
          ref="fileTable"
          :data="tableData"
          highlight-current-row
          :height="tableHeight"
          :default-sort="{ prop: 'create_datetime', order: 'descending' }"
          @selection-change="handleSelectionChange">
        <el-table-column type="expand" width="30">
          <template #default="{ row }">
            <div class="gray-background" v-if="row.text_content">

              <el-text class="title_text">{{ $t('knowledge.file-text') }}</el-text>
              <br>
              <el-text class="multi-line-text" line-clamp="8">{{ row.text_content }}</el-text>
            </div>

          </template>
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="30"/>
        <el-table-column prop="name" :label="$t('knowledge.file-name')" sortable>
          <template #default="{ row }">
            <el-text>{{ row.name }}</el-text>
            <br/>
            <el-text size="small" type="info" style="white-space: nowrap;">MD5: {{ row.hash }}</el-text>
          </template>
        </el-table-column>
        <el-table-column prop="version" :label="$t('knowledge.file-version')" width="80" align="center" sortable>
          <template #default="{ row }">
            <el-text size="default" type="primary">V {{ String(row.version).padStart(2, '0') }}</el-text>
          </template>
        </el-table-column>
        <el-table-column prop="size" :label="$t('knowledge.file-size')" width="80" align="center"
                         sortable>
          <template #default="{ row }">
            {{ formatFileSize(row.size) }}
          </template>
        </el-table-column>
        <el-table-column prop="convert_text_datetime" :label="$t('knowledge.file-status')" width="130" align="center"
                         sortable>
          <template #default="{ row }">
            <el-tooltip
                :content="`${formatDate(row.convert_text_datetime, 'yyyy-MM-dd HH:mm')} ${$t('knowledge.success-text-date')}`"
                placement="bottom-start"
                :disabled="!row.convert_text_datetime"
            >
            <span>
              <span
                  v-if="row.convert_text"
                  class="status-dot green"
              ></span>
              <span
                  v-else
                  class="status-dot grey"
              ></span>
            </span>
            </el-tooltip>
            <el-tooltip
                :content="`${formatDate(row.convert_graph_datetime, 'yyyy-MM-dd HH:mm')} ${$t('knowledge.success-graph-date')}`"
                placement="bottom-start"
                :disabled="!row.convert_graph_datetime"
            >
            <span>
              <span
                  v-if="row.convert_graph"
                  class="status-dot green"
              ></span>
              <span
                  v-else
                  class="status-dot grey"
              ></span>
            </span>
            </el-tooltip>
            <el-tooltip
                :content="`${formatDate(row.convert_vectorization_datetime, 'yyyy-MM-dd HH:mm')} ${$t('knowledge.success-conv-date')}`"
                placement="bottom-start"
                :disabled="!row.convert_vectorization_datetime"
            >
            <span>
              <span
                  v-if="row.convert_vectorization"
                  class="status-dot green"
              ></span>
              <span
                  v-else
                  class="status-dot grey"
              ></span>
            </span>
            </el-tooltip>

          </template>
        </el-table-column>
        <el-table-column prop="create_datetime" :label="$t('knowledge.upload-date')" width="150" align="center"
                         sortable>
          <template #default="{ row }">
            {{ formatDate(row.create_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="uploader" :label="$t('knowledge.uploader')" width="100" align="center"
                         sortable>
          <template #default="{ row }">
            {{ row.uploader }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="90" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" circle plain @click="show_dialog(row)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" @click="deleteFiles(row.file_id)" circle plain>
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50,100,500]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems"
          @size-change="handleSizeChange(pageSize)"
          @current-change="handleCurrentChange(currentPage)"
      />
    </el-col>
  </el-row>
  <el-dialog v-model="modifyFileInfo" @close="cancelFile"
             :title="$t('knowledge.modify-file-title')"
             width="800px"
             align-center center draggable :close-on-click-modal="false"
  >
    <el-alert v-if="errorMessage" type="warning" :description="errorMessage" :closable="false" show-icon
              :effect="'light'" class="error_alert">
    </el-alert>

    <el-form ref="fileModifyForm" :model="cur_File" :rules="fileRules" label-width="80px" :label-position="'right'">
      <el-row :gutter="0">
        <el-col :span="16">
          <el-form-item :label="$t('knowledge.file-name')" prop="name">
            <el-input v-model="cur_File.name"
                      :placeholder="$t('knowledge.file-name-tip')"
                      clearable
                      show-word-limit
                      maxlength="200"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('knowledge.file-version')" prop="version" class="item">
            <el-input-number v-model="cur_File.version" :min="1" :max="99"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="$t('knowledge.file-text')" prop="text_content" class="item">
            <el-input v-model="cur_File.text_content"
                      :placeholder="$t('knowledge.file-text-tip')"
                      :rows="10"
                      type="textarea"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div>
        <el-button type="primary" @click="saveFile(fileModifyForm)">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.save') }}
        </el-button>
        <el-button type="danger" @click="cancelFile" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
  <selectTreeList :modelValue="showDialog" @update:isShow="showDialog = $event"
                  @refreshList="refresh_list_tree" tree-name="file" :file_ids="fileIds"/>
  <UploadKnowledgeFile :modelValue="showUploadDialog" @update:isShow="showUploadDialog = $event"
                       @refreshList="refresh_list_tree" :node_id="cur_node_id"/>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.top-input {
  margin: 0 5px 0 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.search-form {
  margin: 0;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item {
  justify-content: center;
  padding-right: 25px;
}


.gray-background {
  background-color: $menu-bg; /* 设置为你想要的灰色 */
  padding: 20px; /* 可选：添加一些内边距让内容不贴着边 */
  margin-left: 80px;
  margin-right: 15px;
  border-radius: 20px; /* 添加圆角 */
  html.dark & {
    background-color: $main-background-dark;
  }
}

.title_text {
  font-weight: bold;
  color: $table-header-text-color;
}

.error_alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}

.set-color {
  background-color: $table-header-text-color;
  margin-right: 10px;
  width: 0.8px;
  height: 25px;
}

</style>