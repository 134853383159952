<script setup lang="ts">
import {computed, onBeforeMount} from 'vue';
import {useRoute} from 'vue-router';
import MainLayout from './layouts/MainLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import {useLocaleStore} from '@/stores/localStore';
import ElementPlusLocale from "@/setups/element-plus-setup";

const localeStore = useLocaleStore();

const elementPlusLocale = computed(() => (ElementPlusLocale(localeStore.getLocale)));

const route = useRoute();
const layout = computed(() => {
  if (!route.name) return null;
  if (route.name === 'login') {
    return AuthLayout;
  } else {
    return MainLayout;
  }
});

</script>

<template>
  <el-config-provider :locale="elementPlusLocale">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </el-config-provider>
</template>


<style>

</style>
