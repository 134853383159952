import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "col-left" }
const _hoisted_2 = { class: "col-right" }

import {computed, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {Robot} from "@/types/Robot" // 导入 User 类型定义
import {useRobotStore} from "@/stores/robotStore" // 导入用户存储
import {showNotification} from "@/services/tools"
import {Prompt} from "@/types/Prompt"; // 导入工具函数

// 使用用户存储和国际化

export default /*@__PURE__*/_defineComponent({
  __name: 'NewRobot',
  props: { // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  robot_info: {
    type: Object as () => Robot,
    default: null
  }
},
  emits: ["refreshList", "update:isShow"],
  setup(__props, { emit: __emit }) {

const robotStore = useRobotStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newRobotMessage = ref<string>('') // 新模板信息提示
const robotForm = ref<FormInstance>() // 模板表单实例
const robotDialog = ref(false) // 创建对话框显示状态
const robot = ref<Robot>({} as Robot) // 创建用户对象
const RobotData = ref({ // 模板数据对象
  name: '',
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  prompts: [
    {
      prompt_id: -1,
      rb_id: -1,
      name: '',
      description: '',
      command: '',
      publish: false,
      modify_datetime: null,
      create_datetime: null
    } as Prompt  // 初始时包含一个空项目
  ]
})
// 初始化模板数据
const defaultRobotData = {
  name: '',
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  prompts: [
    {
      prompt_id: -1,
      rb_id: -1,
      name: '',
      description: '',
      command: '',
      publish: false,
      modify_datetime: null,
      create_datetime: null
    } as Prompt  // 初始时包含一个空项目
  ]
}

// 定义组件的 props 和 emits
const props = __props
const emit = __emit // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  robotDialog.value = val // 设置对话框显示状态
  newRobotMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.robot_info 的变化并初始化相关状态
watch(() => props.robot_info, (newVal) => {
  if (newVal) {
    robot.value = newVal;
    ini_form(robot.value)
  } else {
    robot.value = {} as Robot;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const robotRules = computed(() => ({
  name: [{required: true, max: 50, message: t('robot.name-check'), trigger: 'blur'}], // 名称验证规则
  description: [{required: true, max: 100, message: t('robot.description-check'), trigger: 'blur'}], // 描述验证规则
  system_role_content: [{required: true, max: 300, message: t('robot.system_role_content-check'), trigger: 'blur'}]
}))

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    robotDialog.value = false // 隐藏对话框
    newRobotMessage.value = "" // 清空提示信息
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

// 上传用户信息
const save_robot = async (formEl: FormInstance | undefined) => {
  if (!formEl) return


  try {
    robot.value.name = RobotData.value.name // 设置模板名称
    robot.value.description = RobotData.value.description // 设置模板描述
    robot.value.system_role_content = RobotData.value.system_role_content // 设置模板系统角色内容
    robot.value.command = RobotData.value.command // 设置模板命令
    robot.value.output_parser = RobotData.value.output_parser // 设置模板输出解析器
    robot.value.publish = RobotData.value.publish // 设置模板发布状态


    newRobotMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        await robotStore.createOrUpdateRobot(robot.value)
        RobotData.value = {...defaultRobotData} //清空PromptData数据
        refreshFatherList() // 刷新父组件列表
        await closeDialog() // 关闭对话框
        showNotification('success', t('message.success'), t('robot.new-success-info')) // 显示成功通知
      }
    } catch (error: any) {
      newRobotMessage.value = "" // 清除错误提示
      let detail = "null"
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'InvalidInput':
          newRobotMessage.value = "传入参数无效";
          break;
        case 'RobotHasExisted':
          newRobotMessage.value = t('robot.name-exist') // 显示用户名已存在提示
          break;
        case 'null':
          break;
        default:
          console.log(error.response.data)
          showNotification('error', '未知错误', t('user.unknown-error'));
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newRobotMessage.value = t('user.unknown-error') // 显示未知错误提示
  }
}

const ini_form = async (robot: Robot) => {
  if (robot) {
    RobotData.value.name = robot.name || '' // 设置用户头像 URL
    RobotData.value.description = robot.description || ''// 设置用户昵称
    RobotData.value.command = robot.command || '' // 设置用户登录名
    RobotData.value.system_role_content = robot.system_role_content || '' // 设置用户密码
    RobotData.value.output_parser = robot.output_parser || ''// 设置用户手机
    RobotData.value.publish = robot.publish || false //设置管发布状态
    RobotData.value.prompts = robot.prompts || [{
      prompt_id: -1,
      rb_id: -1,
      name: '',
      description: '',
      command: '', // 添加 command 字段
      publish: false, // 添加 publish 字段
      modify_datetime: null,
      create_datetime: null
    }] // 设置项目
  }
}


return (_ctx: any,_cache: any) => {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: robotDialog.value,
    onClose: closeDialog,
    title: _ctx.$t('robot.new-title'),
    width: "1100px",
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (save_robot(robotForm.value)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.save')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref_key: "robotForm",
        ref: robotForm,
        model: RobotData.value,
        rules: robotRules.value,
        "label-width": "90px",
        "label-position": 'right'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (newRobotMessage.value)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    type: "warning",
                    description: newRobotMessage.value,
                    closable: false,
                    "show-icon": "",
                    effect: 'light',
                    class: "reg-alert"
                  }, null, 8, ["description"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('robot.name'),
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: RobotData.value.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((RobotData.value.name) = $event)),
                          placeholder: _ctx.$t('robot.name-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "50",
                          style: {"width":"100%"}
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('robot.description'),
                      prop: "description"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: RobotData.value.description,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((RobotData.value.description) = $event)),
                          placeholder: _ctx.$t('robot.description-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "100"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('robot.system_role_content'),
                      prop: "system_role_content"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: RobotData.value.system_role_content,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((RobotData.value.system_role_content) = $event)),
                          placeholder: _ctx.$t('robot.system_role_content-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "300"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('robot.publish'),
                      prop: "publish"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: RobotData.value.publish,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((RobotData.value.publish) = $event)),
                          "inline-prompt": "",
                          "active-text": _ctx.$t('message.yes'),
                          "inactive-text": _ctx.$t('message.no')
                        }, null, 8, ["modelValue", "active-text", "inactive-text"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('robot.command'),
                      prop: "command"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: RobotData.value.command,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((RobotData.value.command) = $event)),
                          placeholder: _ctx.$t('robot.command-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "400"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('robot.output_parser'),
                      prop: "output_parser"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: RobotData.value.output_parser,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((RobotData.value.output_parser) = $event)),
                          placeholder: _ctx.$t('robot.output_parser-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 4,
                          "show-word-limit": "",
                          maxlength: "200"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}
}

})