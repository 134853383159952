import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = {
  key: 0,
  class: "free-label"
}
const _hoisted_3 = {
  key: 1,
  class: "main-label"
}
const _hoisted_4 = {
  key: 2,
  class: "small-label"
}
const _hoisted_5 = {
  key: 3,
  class: "logic-label"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "card-header" }
const _hoisted_8 = { class: "system-content" }
const _hoisted_9 = { class: "system-label" }
const _hoisted_10 = { class: "system-content" }
const _hoisted_11 = { class: "system-label" }
const _hoisted_12 = { class: "card-header" }
const _hoisted_13 = { class: "system-content" }
const _hoisted_14 = { class: "system-label" }
const _hoisted_15 = { class: "system-content" }
const _hoisted_16 = { class: "system-label" }
const _hoisted_17 = { class: "card-header" }
const _hoisted_18 = { class: "system-content" }
const _hoisted_19 = { class: "system-label" }
const _hoisted_20 = { class: "system-content" }
const _hoisted_21 = { class: "system-label" }
const _hoisted_22 = { class: "card-header" }
const _hoisted_23 = { class: "system-content" }
const _hoisted_24 = { class: "system-label" }
const _hoisted_25 = { class: "system-content" }
const _hoisted_26 = { class: "system-label" }
const _hoisted_27 = { class: "card-header" }
const _hoisted_28 = { class: "system-content" }
const _hoisted_29 = { class: "system-label" }
const _hoisted_30 = { class: "system-content" }
const _hoisted_31 = { class: "system-label" }

import {useI18n} from "vue-i18n" // 导入国际化插件
import {keys, SystemOption} from "@/types/system";
import {
  autoHeight,
  formatText,
  loadSystemOptions,
  saveOption,
  saveSystemOptions,
  showLoading,
  showMessageBox,
  showNotification
} from "@/services/tools";
import {ElTable} from "element-plus";

import {onMounted, reactive, ref} from 'vue';
import {Model} from '@/types/LLM'
import {useLLMStore} from "@/stores/llmStore";

// 使用国际化功能

export default /*@__PURE__*/_defineComponent({
  __name: 'llm_setup',
  setup(__props) {

const {t} = useI18n();

// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<Model[]>([]);  // 表格数据
const tableHeight = autoHeight(200);  // 表格高度自适应
const modelStore = useLLMStore();  // 使用用户存储
const modelTable = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const cur_model = ref<Model>();  // 当前选中的模型
const searchData = reactive({
  keyword: ''  // 搜索关键字
});

// 单独保存不同服务的函数，调用通用保存函数
const save_moon = () => saveOption(t, 'moonshot', ['ak', 'url']);
const save_baidu = () => saveOption(t, 'baidu', ['ak', 'sk']);
const save_baichuan = () => saveOption(t, 'baichuan', ['ak', 'url']);
const save_volc = () => saveOption(t, 'volc', ['ak', 'sk']);
const save_openai = () => saveOption(t, 'openai', ['ak', 'url']);

const searchModels = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await modelStore.get_Models(searchData, 1, pageSize.value);
    tableData.value = modelStore.modelsList;
  } finally {
    loading.close();
  }
};

const save_main = async () => {
  if (!cur_model.value) {
    await showMessageBox(t, t('chat.main-llm'), "必须选中一个模型。", true)
    return
  }

  SystemOption['main_llm'] = cur_model.value.supplier
  SystemOption['main_llm' + cur_model.value.supplier] = cur_model.value.model + ":" + cur_model.value.size

  await saveSystemOptions(SystemOption, null);
  await handleCurrentChange(currentPage.value);  // 初始化当前页数据
  showNotification("success", t(`chat.main-llm`), `${t(`chat.main-llm`)} ${t("message.save-options-success")}`);
}

const save_small = async () => {
  if (!cur_model.value) {
    await showMessageBox(t, t('chat.small-llm'), "必须选中一个模型。", true)
    return
  }

  SystemOption['small_llm'] = cur_model.value.supplier
  SystemOption['small_llm' + cur_model.value.supplier] = cur_model.value.model + ":" + cur_model.value.size

  await saveSystemOptions(SystemOption, null);
  await handleCurrentChange(currentPage.value);  // 初始化当前页数据
  showNotification("success", t(`chat.small-llm`), `${t(`chat.small-llm`)} ${t("message.save-options-success")}`);
}

const save_logic = async () => {
  if (!cur_model.value) {
    await showMessageBox(t, t('chat.logic-llm'), "必须选中一个模型。", true)
    return
  }

  SystemOption['logic_llm'] = cur_model.value.supplier
  SystemOption['logic_llm' + cur_model.value.supplier] = cur_model.value.model + ":" + cur_model.value.size

  await saveSystemOptions(SystemOption, null);
  await handleCurrentChange(currentPage.value);  // 初始化当前页数据
  showNotification("success", t(`chat.logic-llm`), `${t(`chat.logic-llm`)} ${t("message.save-options-success")}`);
}

const handleSelectionChange = (val: Model | undefined) => {
  cur_model.value = val;
};


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await modelStore.get_Models(searchData.keyword, currentPage.value, val);
    pageSize.value = val;
    tableData.value = modelStore.modelsList;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await modelStore.get_Models(searchData.keyword, val, pageSize.value);
    currentPage.value = val;
    tableData.value = modelStore.modelsList;
  } finally {
    loading.close();
  }
};

onMounted(async () => {
  try {
    await loadSystemOptions();

    // 初始化密钥值
    Object.keys(keys).forEach(key => {
      const typedKey = key as keyof typeof keys;
      keys[typedKey].value = SystemOption[typedKey];
    });

    const ps_key = localStorage.getItem("model_page_size");
    if (ps_key) {
      pageSize.value = Number(ps_key);
    }
    await handleCurrentChange(1);  // 初始化加载第一页数据
  } catch (error) {
    console.error('初始化失败:', error);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 14 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "top-row" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_form, {
                  ref: "searchForm",
                  model: searchData,
                  inline: true,
                  class: "search-form",
                  onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      prop: "searchKey",
                      class: "keyword-input"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: searchData.keyword,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchData.keyword) = $event)),
                          placeholder: _ctx.$t('user.SearchKey'),
                          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (searchModels(searchData.keyword)), ["enter"])),
                          style: {"width":"200px"},
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (searchModels(searchData.keyword))),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_el_button, {
                  type: "primary",
                  size: "large",
                  onClick: save_main,
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('chat.main-llm')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "success",
                  size: "large",
                  onClick: save_small,
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('chat.small-llm')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "warning",
                  size: "large",
                  onClick: save_logic,
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('chat.logic-llm')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_divider),
              _createVNode(_unref(ElTable), {
                ref_key: "modelTable",
                ref: modelTable,
                data: tableData.value,
                "highlight-current-row": "",
                stripe: "",
                height: _unref(tableHeight),
                "default-sort": { prop: 'supplier', order: 'descending' },
                onCurrentChange: handleSelectionChange
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "supplier",
                    label: _ctx.$t('chat.model-supplier'),
                    width: "100",
                    sortable: "",
                    align: "center"
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    width: "60",
                    align: "center"
                  }, {
                    default: _withCtx(({ row }) => [
                      (row.free)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "FREE"))
                        : _createCommentVNode("", true),
                      (row.model+':'+row.size===_unref(SystemOption)['main_llm'+_unref(SystemOption)['main_llm']])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " MAIN "))
                        : _createCommentVNode("", true),
                      (row.model+':'+row.size===_unref(SystemOption)['small_llm'+_unref(SystemOption)['small_llm']])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, "SMALL "))
                        : _createCommentVNode("", true),
                      (row.model+':'+row.size===_unref(SystemOption)['logic_llm'+_unref(SystemOption)['logic_llm']])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, "LOGIC "))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "name",
                    label: _ctx.$t('chat.model-name'),
                    width: "180",
                    sortable: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "size",
                    label: _ctx.$t('chat.model-size'),
                    width: "80",
                    align: "center",
                    sortable: ""
                  }, null, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "desc",
                    label: _ctx.$t('chat.model-desc'),
                    class: "multi-line-text"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("span", {
                        innerHTML: _unref(formatText)(scope.row.desc)
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["data", "height"]),
              _createVNode(_component_el_pagination, {
                "current-page": currentPage.value,
                "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => ((currentPage).value = $event)),
                "page-size": pageSize.value,
                "onUpdate:pageSize": _cache[5] || (_cache[5] = ($event: any) => ((pageSize).value = $event)),
                "page-sizes": [10, 20, 50],
                layout: "total, sizes, prev, pager, next, jumper",
                total: totalItems.value,
                onSizeChange: _cache[6] || (_cache[6] = ($event: any) => (handleSizeChange(pageSize.value))),
                onCurrentChange: _cache[7] || (_cache[7] = ($event: any) => (handleCurrentChange(currentPage.value)))
              }, null, 8, ["current-page", "page-size", "total"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 5 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("chat.moonshot")), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_moon
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("chat.moonshot-ak")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).moonshot_ak.value,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(keys).moonshot_ak.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("chat.moonshot-url")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).moonshot_url.value,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(keys).moonshot_url.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("chat.baidu")), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_baidu
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("chat.baidu-ak")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).baidu_ak.value,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(keys).baidu_ak.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("chat.baidu-sk")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).baidu_sk.value,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(keys).baidu_sk.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("chat.baichuan")), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_baichuan
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("chat.baichuan-ak")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).baichuan_ak.value,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(keys).baichuan_ak.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("chat.baichuan-url")) + "Y", 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).baichuan_url.value,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(keys).baichuan_url.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 5 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("chat.volc")), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_volc
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("chat.volc-ak")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).volc_ak.value,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(keys).volc_ak.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("chat.volc-sk")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).volc_sk.value,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(keys).volc_sk.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_card, {
            shadow: "hover",
            class: "system-card"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("chat.openai")), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                round: "",
                onClick: save_openai
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "floppy-disk",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("message.save")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("chat.openai-ak")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).openai_ak.value,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(keys).openai_ak.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("chat.openai-url")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _unref(keys).openai_url.value,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(keys).openai_url.value) = $event)),
                  type: "password",
                  "show-password": "",
                  maxlength: "200",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})